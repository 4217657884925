import React, { Component } from 'react';

import { arraysEqual, hideResidentsCheck, TYPE_OF_PEOPLE, BASE_ONLY_TYPE_OF_PEOPLE } from '../../utilities/Generic.js'
import { capitalizeAllWords } from '../../utilities/Forms.js'

class TypeOfPersonSelect extends Component {
  state = {
    hideResidents: false,
    baseLicense: true,
    options: this.props.options || TYPE_OF_PEOPLE
  }

  filterOptions(baseLicense, hideResidents) {
    let options = this.props.options || TYPE_OF_PEOPLE

    if (!baseLicense) {
      // Remove options which require the base License
      options = options.filter(option => BASE_ONLY_TYPE_OF_PEOPLE.findIndex(i => i === option) === -1)
    }

    if (hideResidents) {
      options = options.filter(option => option !== "resident")
    }

    return options
  }

  renderOption(option) {
    let disabled = false

    if (this.props.disabledOptions && this.props.disabledOptions.findIndex(disabledOption => disabledOption === option) !== -1) {
      // Disable options added to this.props.disabledOptions
      disabled = true
    }

    return <option value={option} key={option} disabled={disabled}>{capitalizeAllWords(option)}</option>
  }

  render() {
    return (
      <select name={this.props.name || "type_of_person"} value={this.props.value} onChange={this.props.onChange} data-testid="select-type-of-person">
        <option value="">{this.props.defaultText || "Select"}</option>
        {this.state.options.map(option => this.renderOption(option))}
      </select>
    )
  }

  componentDidUpdate(prevProps) {
    // Recalculate options when this.props.options changes
    if (!arraysEqual(prevProps.options, this.props.options)) {
      this.setState({
        options: this.filterOptions(this.state.baseLicense, this.state.hideResidents)
      })
    }
  }

  componentDidMount() {
    const hideResidents = hideResidentsCheck();
    const baseLicense = localStorage.getItem("license") === "base";

    this.setState({
      hideResidents: hideResidents,
      baseLicense: baseLicense,
      options: this.filterOptions(baseLicense, hideResidents)
    })
  }
}


export default TypeOfPersonSelect;
