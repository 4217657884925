import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { request, reportErrorToHoneybadger } from '../../helpers/Requests'
import LoggedOutHeader from '../../components/header/LoggedOutHeader';
import SetTitle from '../../components/shared/SetTitle';
import BigLogo from '../../components/header/BigLogo';

import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';

const SignUpStart = () => {
  const [clientSecret, setClientSecret] = useState(null);
  const [clientSecretLoaded, setClientSecretLoaded] = useState(false);
  const [stripePromise] = useState(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {}));
  const [error, setError] = useState(null);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    // Fetch the client_secret key from Stripe
    request('POST', "/v1/a/sign_up", null, () => { setUnauthorized(true) })
      .then(data => {
        setClientSecret(data.client_secret)
        setClientSecretLoaded(true)
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        setError(error)
      })
  }, []);

  if (unauthorized) {
    return <Redirect to="/login"/>
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  if (clientSecretLoaded === false) {
    return <p>Loading ...</p>;
  }

  const options = {
    clientSecret: clientSecret
  }

  if (clientSecretLoaded) {
    return (
      <div className="login-page">
        <SetTitle title={"Sign Up"} />
        <LoggedOutHeader />

        <div className="center-container">
            <p className="welcome-to">Welcome to...</p>

            <BigLogo />

            <p>Please enter your card details below to set up a reoccurring subscription.</p>
            <p>Once completed, we will create an Organisation and Location for you to start using.</p>
            <p>If you know you need multiple Locations, you may change the quantity now. Otherwise, you can upgrade at a later time.</p>

            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
      </div>
    )
  }
};

export default SignUpStart;
