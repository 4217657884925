import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ShowFaq from '../../components/support/ShowFaq';
import { debounce, renderScoreComment } from '../../utilities/Generic.js'

import SlidingPane from "../../components/shared/ScrollableSlidingPane";

class ListOfFaqs extends Component {
  constructor(props) {
    super(props);

    this.handleShowPanel = this.handleShowPanel.bind(this);
    this.handlePartialChange = this.handlePartialChange.bind(this);
    this.debouncedHandleSearch = debounce(this.handleSearch.bind(this), 300);
  }

  state = {
    faqs: [],
    search: "",
    loaded: true,
    loading: false,
    error: null,

    showId: null,
    showPanelToggle: false
  };

  handlePartialChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({
      [name]: value,
      loaded: false
    });

    this.debouncedHandleSearch()
  }

  handleSearch() {
    if (this.state.search === "") {
      this.setState({
        loaded: true,
        faqs: []
      });
      return
    }

    this.setState({loading: true})

    // normal API fetching stuff
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/faqs?faqs[search]=${this.state.search}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => this.setState({ faqs: data, loaded: true, loading: false }))
      .catch(error => this.setState({ error, loaded: true, loading: false }))
  }

  handleShowPanel(event) {
    let id = event.currentTarget.id;

    this.setState({
      showId: id,
      showPanelToggle: true
    });

    this.props.history.push(`/support/faqs/${id}`)
  }

  renderAnswers() {
    const { faqs, search, loaded, loading } = this.state;

    if (loading) {
      return (
        <div className="answers">
          <div className="loading-segment">
            <h3>Loading...</h3>
          </div>
        </div>
      )
    }
    else if (loaded === true && faqs.length === 0 && search !== "") {
      return (
        <div className="answers">
          <div className="loading-segment">
            <h3>Sorry, no FAQs found. Please try broadening your search terms.</h3>
          </div>
        </div>
      );
    }
    else {
      return (
        <div className="answers">
          {faqs.map((faq) => (
            <Link to={`/support/faqs/${faq.id}`} className="subtle-link" onClick={this.handleShowPanel} id={faq.id} key={faq.id}>
              <div className="faq-segment">
                <h3>{faq.question}</h3>
                <div dangerouslySetInnerHTML={{__html: faq.answer}}></div>
                {renderScoreComment(faq.positive_score)}
              </div>
            </Link>
          ))}
        </div>
      )
    }
  }

  render() {
    const { search, error, unauthorized } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    return (
      <div>
        <div className="faq-search-box">
          <h3 className="inline-block">FAQs</h3>
          <div className="input-box">
            <input placeholder="Search FAQs" type="text" name="search" value={search} onChange={this.handlePartialChange} />
          </div>

          {this.renderAnswers()}
        </div>

        <SlidingPane isOpen={this.state.showPanelToggle} title="Show FAQ" width="80%"
          onRequestClose={() => {
            this.setState({ showPanelToggle: false });
            this.setState({ showId: null });
            this.props.history.push('/support');
            this.componentDidMount();
          }}>
          <ShowFaq id={this.state.showId} />
        </SlidingPane>

      </div>
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (state.newPanelToggle && props.id !== undefined) {
      return {
        newPanelToggle: false,
        showPanelToggle: true,
        showId: props.id
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.pathname.endsWith("/support")) {
      return {
        newPanelToggle: false,
        showPanelToggle: false,
        showId: null
      }
    }
    else if (props.history.action === "POP" && props.history.location.pathname.endsWith("/new")) {
      return {
        newPanelToggle: true,
        showPanelToggle: false,
        showId: null
      }
    }
    else if (props.history.action === "POP" && props.id !== undefined) {
      return {
        newPanelToggle: false,
        showPanelToggle: true,
        showId: props.id
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep linking
    if (this.props.id !== undefined && this.state.showPanelToggle === false) {
      this.setState({
        showId: this.props.id,
        showPanelToggle: true
      });
    }
  }
}

export default withRouter(ListOfFaqs);
