import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { request, reportErrorToHoneybadger } from '../../helpers/Requests'

import UpdateBulletin from '../../components/bulletins/UpdateBulletin';
import CreateBulletin from '../../components/bulletins/CreateBulletin';

import { hideElementForNonAdmins } from '../../utilities/Forms.js'

import SlidingPane from "../../components/shared/ScrollableSlidingPane";

class ListOfBulletins extends Component {
  constructor(props) {
    super(props);

    this.handleNewPanel = this.handleNewPanel.bind(this);
    this.handleEditPanel = this.handleEditPanel.bind(this);
    this.closeEditPanel = this.closeEditPanel.bind(this);
  }

  state = {
    bulletins: [],
    loaded: false,
    error: null,

    editId: null,
    newPanelToggle: false,
    editPanelToggle: false
  };

  handleNewPanel(event) {
    this.setState({newPanelToggle: true});
    this.props.history.push('/bulletins/new')
  }

  handleEditPanel(event) {
    let id = event.currentTarget.id;

    this.setState({editId: id});
    this.setState({editPanelToggle: true});
    this.props.history.push(`/bulletins/${id}`)
  }

  closeEditPanel() {
    this.setState({
      editPanelToggle: false,
      editId: null
    });
    this.props.history.push('/bulletins');
    this.componentDidMount();
  }

  render() {
    const { bulletins, loaded, error, unauthorized } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div>
          <h3 className="inline-block">List of bulletins</h3>

          <Link to={'/bulletins/new'} onClick={this.handleNewPanel}>
            <div className={`button new-button ${hideElementForNonAdmins()}`}>New Bulletin</div>
          </Link>

          <div className="table big-table">
            <div className="tr heading">
              <div className="th">Title</div>
              <div className="th">Body</div>
              <div className="th">Sent At</div>
              <div className="th">Filter</div>
            </div>
            {bulletins.map((bulletin) => (
                <Link to={`/bulletins/${bulletin.id}`} onClick={this.handleEditPanel} id={bulletin.id} key={bulletin.id} className="tr">
                  <div className="td">{bulletin.title}</div>
                  <div className="td">{bulletin.body}</div>
                  <div className="td">{bulletin.sent_at}</div>
                  <div className="td">{bulletin.filter}</div>
                </Link>
            ))}
          </div>

          <SlidingPane isOpen={this.state.newPanelToggle} title="New Bulletin" width="60%"
            onRequestClose={() => {
              this.setState({ newPanelToggle: false });
              this.props.history.push('/bulletins');
              this.componentDidMount();
            }}>
            <CreateBulletin />
          </SlidingPane>

          <SlidingPane isOpen={this.state.editPanelToggle} title="Edit Bulletin" width="60%"
            onRequestClose={() => {
              this.closeEditPanel()
            }}>
            <UpdateBulletin id={this.state.editId} closeEditPanel={this.closeEditPanel} />
          </SlidingPane>

        </div>
      );
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.newPanelToggle && props.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.id
      }
    }
    else if (state.editPanelToggle && props.id === undefined) {
      return {
        editPanelToggle: false
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.pathname.endsWith("/bulletins")) {
      return {
        newPanelToggle: false,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.history.location.pathname.endsWith("/new")) {
      return {
        newPanelToggle: true,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.id
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep linking
    if (this.props.location.pathname.endsWith("/new") && this.state.newPanelToggle === false) {
      this.setState({
        newPanelToggle: true
      });
    }
    else if (this.props.id !== undefined && this.state.editPanelToggle === false) {
      this.setState({
        editId: this.props.id,
        editPanelToggle: true
      });
    }

    // normal API fetching stuff
    request('GET', `/v1/a/bulletins`, null, () => {this.setState({ unauthorized: true })})
      .then(data => this.setState({ bulletins: data, loaded: true }))
      .catch(error => {
        reportErrorToHoneybadger(error)

        this.setState({ error, loaded: true })
      })
  }
}

export default withRouter(ListOfBulletins);
