import React from "react"; 

export function showExcludedFeedbackTypes(_this) {
  let text = "Excluded Feedback Types: "
  if (_this.state.excludedFeedbackTypes.length > 0) {
    for (let i=0; i < _this.state.excludedFeedbackTypes.length; i++) {
      if (i > 0 && i===_this.state.excludedFeedbackTypes.length-1) {
        text += " and "
      }
      else if (i > 0) {
        text += ", "
      }
      text += "\""+_this.state.excludedFeedbackTypes[i]+"\""
    }
    if (_this.state.excludedFeedbackTypes.length > 1) {
      return (
        <div><span style={{cursor: "context-menu"}} title={text}>{_this.state.excludedFeedbackTypes.length} Feedback Types</span> have been excluded from Overview.
        </div>
      )
    }
    else {
      return (
        <div><span style={{cursor: "context-menu"}} title={text}>{_this.state.excludedFeedbackTypes.length} Feedback Type</span> has been excluded from Overview.
        </div>
      )
    }
  }
}

export function debounce(func, timeout = 500) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function optionalParam(optional_param, value) {
  if (value.length > 0 || value === true) {
    return `${optional_param}=${value}`
  }
  else {
    return ""
  }
}

export function buildParams (...optionalParams) {
  return `${optionalParams.filter(param => param !== "").join("&")}`
}

export function formatNewLines(text) {
  var key = 0
  return text.split('\n').map(line => <p key={key += 1}>{line}</p>)
}

export function renderScoreComment(positive_score) {
  // If you don't have anything nice to say, don't say anything at all.
  if (positive_score === 0) {
    return ""
  }

  let pluralize = positive_score > 1 ? "s" : "";

  return <div className="score-comments small-top-padding"><em>{positive_score} user{pluralize} found this helpful.</em></div>
}

export function renderAlternateName(person) {
  if (person.alternate_name !== null && person.alternate_name !== "" && person.alternate_name !== undefined) {
    return `(${person.alternate_name})`
  }
}

export function renderFullName(person) {
  if (person.display_name) {
    return `${person.display_name} ${person.last_name}`
  }

  let alternate_name = renderAlternateName(person)
  if (alternate_name) {
    return `${person.first_name} ${alternate_name} ${person.last_name}`
  }

  return `${person.first_name} ${person.last_name}`
}

export function convertToSlug(name) {
  return name
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/[^\w-]+/g, ''); // Remove any non-alphanumeric characters except hyphens
}

export function formatDate(date, minus) {
  if (minus === undefined) {
    minus = 0
  }

  date.setDate(date.getDate() - minus);
  let day = ("0" + date.getDate()).slice(-2)
  let month = ("0" + (date.getMonth() + 1)).slice(-2)
  let year = date.getFullYear()

  return `${year}-${month}-${day}`
}

export function convertTime(hour, includeSuffix = true) {
  let suffix
  if (hour < 12) {
    suffix = "am"
  }
  else if (hour > 11) {
    if (hour !== 12) {
      hour = hour - 12
    }
    suffix = "pm"
  }

  return includeSuffix === true ? `${hour}${suffix}` : hour
}

export function datify(dateString) {
  if (dateString === "N/A") {
    return (new Date(1970))
  }
  else {
    let [dateInfo, timeInfo] = dateString.split(" ")

    // Convert time within string from 12 to 24 hour clock
    let [hour, minutes] = timeInfo.slice(0, -2).split(":");
    let amOrPm = timeInfo.slice(-2);

    if (amOrPm === "am") {
      if (hour === "12") {
        hour = "0"
      }
    }
    else {
      if (hour !== "12") {
        hour = parseInt(hour) + 12
      }
    }

    // Convert date to yyyy/mm/dd
    let [day, month, year] = dateInfo.split("/")
    return new Date(`${year}/${month}/${day} ${hour}:${minutes}`)
  }
}

export function getContrastYIQ(hexcolor) {
    if (!hexcolor) {
      return "black"
    }

    var r = parseInt(hexcolor.substring(1,3),16);
    var g = parseInt(hexcolor.substring(3,5),16);
    var b = parseInt(hexcolor.substring(5,7),16);
    var yiq = ((r*299)+(g*587)+(b*114))/1000;
    return (yiq >= 128) ? 'black' : 'white';
  }

export function futureEndDayOfWeek(start_day_of_week, start_time, end_time) {
  let end_day_of_week = start_day_of_week

  if (start_time > end_time) {
    end_day_of_week = parseInt(start_day_of_week) + 1
  }

  return end_day_of_week
}

export function futureEndDate(start_date, start_time, end_time) {
  let endDate = new Date(start_date);

  if (start_time > end_time) {
    endDate.setDate(endDate.getDate() + 1);
  }

  return endDate;
}

export function arrayParamBuilder(resourceName, attributeName, ids) {
  // Convert array of integers into GET request compatible params
  // Not necessary for stringified JSON

  if (ids === "all") {
    return ""
  }

  let param = ""
  for (let id of ids) {
    if (resourceName === null) {
      // i.e. `&tag_ids[]=1&tag_ids[]=2`
      param += `&${attributeName}[]=${id}`
    }
    else {
      // i.e. `&people[custom_attribute_ids][]=1&people[custom_attribute_ids][]=2`
      param += `&${resourceName}[${attributeName}][]=${id}`
    }
  }

  return param
}

export function listWithGrammar(array) {
  if (array.length === 0) {
    return ""
  }

  if (array.length === 1) {
    return array[0]
  }

  let comma_separated_array = array.slice(0, -1).join(", ")

  return `${comma_separated_array} and ${array[array.length - 1]}`
}

export function renderSortColumn(title, attribute){
  if (this.props.sort === attribute) {
    let directionArrow = "▲"
    if (this.props.direction === "desc") {
      directionArrow = "▼"
    }
    return (
      <div className="th sortable sorted" onClick={() => this.props.handleSortChange(attribute)}>
        <strong>{title}</strong>
        <div><strong>{directionArrow}</strong></div>
      </div>
    )
  }
  else {
    return (
      <div className="th sortable" onClick={() => this.props.handleSortChange(attribute)}>
        {title}
      </div>
    )
  }
}

export function arraysEqual(a, b) {
  if (a === b) {
    return true
  }
  if (a == null || b == null) {
    return false
  }
  if (a.length !== b.length) {
    return false
  }

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) {
      return false
    }
  }

  return true;
}

export function hideResidentsCheck() {
  return localStorage.getItem("type_of_organisation") === "Retail"
}

export const docExtensionsArray = ["doc", "docm", "docx", "dot", "dotx", "txt"];
export const videoExtensionsArray = ["mp4", "mpg", "mpeg", "mov", "avi"];
export const pdfExtensionsArray = ["pdf"];
export const imageExtensionsArray = ["png", "jpg", "jpeg", "gif", "bmp", "jfif", "tif", "webp"];
export const csvExtensionsarray = ["csv", "xls", "xlsm", "xlsx"];

export const nonImageExtensionsArray = docExtensionsArray.concat(videoExtensionsArray).concat(pdfExtensionsArray).concat(csvExtensionsarray);

export const TYPE_OF_PEOPLE = ["visitor", "resident", "staff", "contractor", "industry professional", "other"]
export const BASE_ONLY_TYPE_OF_PEOPLE = ["resident", "staff", "contractor", "industry professional", "other"]
export const STAFF_TYPE_OF_PEOPLE = ["staff", "contractor"]
