import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import UpdateProfile from '../../components/profiles/UpdateProfile';
import CreateProfile from '../../components/profiles/CreateProfile';

import { hideElementForNonAdmins } from '../../utilities/Forms.js'

import { faCheckCircle, faTimes, faArrowsAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SlidingPane from "../../components/shared/ScrollableSlidingPane";

import { DragDropContext, Draggable  } from 'react-beautiful-dnd';
import Droppable from '../../components/shared/StrictModeDroppable'

class ListOfProfiles extends Component {
  constructor(props) {
    super(props);

    this.handleNewPanel = this.handleNewPanel.bind(this);
    this.handleEditPanel = this.handleEditPanel.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.closeEditPanel = this.closeEditPanel.bind(this);
  }

  state = {
    profiles: [],
    loaded: false,
    error: null,

    editId: null,
    newPanelToggle: false,
    editPanelToggle: false
  };

  handleNewPanel(event) {
    this.setState({newPanelToggle: true});
    this.props.history.push('/configuration/profiles/new')
  }

  handleEditPanel(event) {
    let id = event.currentTarget.id;

    this.setState({editId: id});
    this.setState({editPanelToggle: true});
    this.props.history.push(`/configuration/profiles/${id}`)
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const profiles = this.reorder(
      this.state.profiles,
      result.source.index,
      result.destination.index
    );

    this.setState({
      profiles: profiles
    });

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var reorderList = []
    for (var i = 0; i < profiles.length; i++) {
      reorderList.push({id: profiles[i].id, order: profiles[i].order})
    }

    var json = JSON.stringify({
      "profiles": {
        "reorder": reorderList
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/profiles/reorder`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
  }

  closeEditPanel() {
    this.setState({
      editPanelToggle: false,
      editId: null
    });
    this.props.history.push('/configuration/profiles');
    this.componentDidMount();
  }

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    // now, reassign profile.order numbers
    var newResult = []
    for (var i = 0; i < result.length; i++) {
      var profile = result[i]
      profile.order = i + 1

      newResult.push(profile)
    }

    return newResult;
  };

  indexBackground(index) {
    if (index % 2 === 0) {
      return "#eee"
    }
    else {
      return "#fff"
    }
  }

  render() {
    const { profiles, loaded, error, unauthorized } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    const getItemStyle = (isDragging, draggableStyle, index) => ({
      backgroundColor: isDragging ? "lightgreen" : this.indexBackground(index),
      ...draggableStyle
    });

    if (loaded) {
      const baseLicense = localStorage.license === "base"

      return (
        <div>
          <h3 className="inline-block">List of profiles</h3>

          {baseLicense && (
            <Link to={'/configuration/profiles/new'} onClick={this.handleNewPanel}>
              <div className={`button new-button ${hideElementForNonAdmins()}`}>New Profile</div>
            </Link>
          )}

          <DragDropContext onDragEnd={this.onDragEnd} className="table big-table">
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} className="drag-and-drop" ref={provided.innerRef} >
                  <div className="row six-columns heading">
                    <div className="th"></div>
                    <div className="th">Order</div>
                    <div className="th">Name</div>
                    <div className="th">Display name</div>
                    <div className="th">Devices</div>
                    <div className="th">Hidden</div>
                  </div>

                  {profiles.map((profile, index) => (
                    <Draggable key={`key-${profile.id}`} draggableId={`key-${profile.id}`} index={index}>
                      {(provided, snapshot) => (
                        <Link to={`/configuration/profiles/${profile.id}`} onClick={this.handleEditPanel} id={profile.id} className="row six-columns" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, index)} >
                          <div className="td drag-icon"><FontAwesomeIcon icon={faArrowsAlt} /></div>
                          <div className="td">{profile.order}</div>
                          <div className="td">{profile.name}</div>
                          <div className="td">{profile.display_name}</div>
                          <div className="td">{profile.number_of_devices}</div>
                          <div className="td center">{profile.hidden ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}</div>
                        </Link>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <SlidingPane isOpen={this.state.newPanelToggle} title="New Profile" width="60%"
            onRequestClose={() => {
              this.setState({ newPanelToggle: false });
              this.props.history.push('/configuration/profiles');
              this.componentDidMount();
            }}>
            <CreateProfile order={profiles.length + 1}/>
          </SlidingPane>

          <SlidingPane isOpen={this.state.editPanelToggle} title="Edit Profile" width="60%"
            onRequestClose={() => {
              this.closeEditPanel()
            }}>
            <UpdateProfile id={this.state.editId} closeEditPanel={this.closeEditPanel} />
          </SlidingPane>

        </div>
      );
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.newPanelToggle && props.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.id
      }
    }
    else if (state.editPanelToggle && props.id === undefined) {
      return {
        editPanelToggle: false
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.pathname.endsWith("/profiles")) {
      return {
        newPanelToggle: false,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.history.location.pathname.endsWith("/new")) {
      return {
        newPanelToggle: true,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.id
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep linking
    if (this.props.location.pathname.endsWith("/new") && this.state.newPanelToggle === false) {
      this.setState({
        newPanelToggle: true
      });
    }
    else if (this.props.id !== undefined && this.state.editPanelToggle === false) {
      this.setState({
        editId: this.props.id,
        editPanelToggle: true
      });
    }

    // normal API fetching stuff
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/profiles`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => this.setState({ profiles: data, loaded: true }))
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default withRouter(ListOfProfiles);
