import React, { Component } from 'react';

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

// This wrapper allows keyboard scrolling of the SlidingPane
class ScrollableSlidingPane extends Component {
  render() {
    return (
      <SlidingPane
        isOpen={this.props.isOpen}
        title={this.props.title}
        subtitle={this.props.subtitle}
        from={this.props.from}
        children={this.props.children}
        className={this.props.className}
        overlayClassName={this.props.overlayClassName}
        width={this.props.width}
        closeIcon={this.props.closeIcon}
        shouldCloseOnEsc={this.props.shouldCloseOnEsc}
        hideHeader={this.props.hideHeader}
        onRequestClose={this.props.onRequestClose}
        onAfterOpen={() => {
          this.makeScrollable()
          if (this.props.onAfterOpen) {
            this.props.onAfterOpen()
          }
        }}
      />

    )
  }

  makeScrollable() {
    let panelContent = document.querySelectorAll('.slide-pane__content')
    let lastPanelContent = panelContent[panelContent.length - 1]
    lastPanelContent.tabIndex = -1
    lastPanelContent.focus({focusVisible: false})
  }
}


export default ScrollableSlidingPane;
