import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/header/Header';
import OrganisationName from '../../components/shared/OrganisationName';
import DailyArrivals from '../../components/locations/charts/DailyArrivals';
import Devices from '../../components/locations/Devices';
import FireLog from '../../components/locations/FireLog';
import LocationName from '../../components/locations/LocationName';
import People from '../../components/locations/People';
import PeopleOnSite from '../../components/locations/charts/PeopleOnSite';
import WeeklyFeedback from '../../components/locations/charts/WeeklyFeedback';
import OutstandingFeedback from '../../components/locations/charts/OutstandingFeedback';

import BtnEdit from '../../images/btn_edit_768x512.png';
import UpdateLocation from '../../components/locations/UpdateLocation';
import DevicesAndProfiles from '../../components/locations/DevicesAndProfiles';

import SlidingPane from "../../components/shared/ScrollableSlidingPane";

import PopupBox from '../../components/shared/PopupBox';

class LocationShow extends Component {
  constructor(props) {
    super(props);

    this.handleEditPanel = this.handleEditPanel.bind(this);
    this.handleFireLogPanel = this.handleFireLogPanel.bind(this);
    this.closeEditPanel = this.closeEditPanel.bind(this);
    this.updateName = this.updateName.bind(this);
  }

  state = {
    name: null,
    editPanelToggle: false,
    fireLogPanelToggle: false
  };

  handleEditPanel(event) {
    this.setState({editPanelToggle: true});
    this.props.history.push(`/location/${this.props.match.params.id}/edit`)
  }

  handleFireLogPanel(event) {
    this.setState({fireLogPanelToggle: true});
    this.props.history.push(`/location/${this.props.match.params.id}/fire-log`)
  }

  closeEditPanel() {
    this.setState({
      editPanelToggle: false
    });
    this.props.history.push(`/location/${this.props.match.params.id}/`);
  }

  updateName(newName) {
    this.setState({
      name: newName
    })
  }

  render() {
    const { match: { params } } = this.props;

    const baseLicense = localStorage.license === "base"
    const hideFromPrintWhenPanelOpen = this.state.fireLogPanelToggle ? "no-print" : ""

    return (
      <div>
        <PopupBox />
        <Header />

        <div className={`main-page ${hideFromPrintWhenPanelOpen}`}>
          <h2 className="page-title"><LocationName id={params.id} name={this.state.name}/></h2>

          <div onClick={this.handleEditPanel} className={'edit-button'}>
            <h4>Edit Location</h4>
            <img className="edit-icon" src={BtnEdit} alt="Edit Location" width="40" />
          </div>

          <OrganisationName />

          <div className="widgets">
            <DailyArrivals id={params.id} />

            { baseLicense ? (
              <Link to={`/location/${params.id}/fire-log`} onClick={this.handleFireLogPanel} className="inline-block fire-log">
                <PeopleOnSite id={params.id} />
              </Link>
            ) : (
              <div className="inline-block" style={{width: "550px"}}>
                <PeopleOnSite id={params.id} />
              </div>
            )}

            <WeeklyFeedback id={params.id} />
            { baseLicense && <OutstandingFeedback id={params.id} /> }
            <Devices id={params.id} />
            <People id={params.id} />
          </div>
        </div>

        <SlidingPane isOpen={this.state.editPanelToggle} title="Edit Location" width="60%"
          onRequestClose={() => {
            this.closeEditPanel()
          }}>
          <UpdateLocation id={params.id} closeEditPanel={this.closeEditPanel} updateName={this.updateName} />
          <DevicesAndProfiles id={params.id} />
        </SlidingPane>

        <SlidingPane isOpen={this.state.fireLogPanelToggle} title="Fire Log" width="60%" className="print-full-width"
          onRequestClose={() => {
            this.setState({ fireLogPanelToggle: false });
            this.props.history.push(`/location/${params.id}`);
          }}>
          <FireLog id={params.id} fire_log_id={params.fire_log_id} />
        </SlidingPane>

      </div>
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (state.editPanelToggle && props.location.pathname.endsWith("/edit") === false){
      return {
        editPanelToggle: false
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.pathname.endsWith("/edit")) {
      return {
        editPanelToggle: true
      }
    }
    else if (props.history.action === "POP" && props.history.location.pathname.includes("/fire-log")) {
      return {
        fireLogPanelToggle: true
      }
    }
    else if (props.history.action === "POP") {
      return {
        editPanelToggle: false,
        fireLogPanelToggle: false
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep linking
    if (this.props.location.pathname.endsWith("/edit") && this.state.editPanelToggle === false) {
      this.setState({editPanelToggle: true});
    }
    else if (this.props.location.pathname.includes("/fire-log") && this.state.fireLogPanelToggle === false) {
      this.setState({fireLogPanelToggle: true});
    }
  }
}

export default LocationShow;
