import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import 'chart.js/auto';
import { Bar, getElementAtEvent } from 'react-chartjs-2';

import { request, reportErrorToHoneybadger } from '../../../helpers/Requests'

import EventsPanel from '../../../components/shared/EventsPanel';

import { capitalize } from '../../../utilities/Forms.js'

import SlidingPane from "../../../components/shared/ScrollableSlidingPane";

class AnalysisFeedbackByPeople extends Component {
  constructor(props) {
    super(props);

    this.barGraph = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.openPanel = this.openPanel.bind(this);
  }

  handleClick(event) {
    // do not try to open events panel when charts are not being clicked
    if (getElementAtEvent(this.barGraph.current, event)[0] === undefined) {
      return
    }

    const index = getElementAtEvent(this.barGraph.current, event)[0].index
    this.openPanel(index)
  }

  openPanel(index) {
    let type;
    // eslint-disable-next-line
    switch(index) {
      case 0:
        type = "positive"
        break;
      case 1:
        type = "neutral"
        break;
      case 2:
        type = "negative"
        break;
    }

    this.setState({
      infoPanel: true,
      type: type
    })

    this.props.history.push(`/analysis/overview/${this.props.person}/${type}${this.props.location.search}`)
  }

  render() {
    const { json, loaded, error, unauthorized, invalid_params, infoPanel, type } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (invalid_params === true) {
      return <p>Waiting for valid date range ...</p>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      const data = {
        labels: Object.keys(json),
        datasets: [{
          data: [
            json.positive.length,
            json.neutral.length,
            json.negative.length
          ],

          backgroundColor: ['#22ee80', '#eee622', '#ce5252']
        }]
      }

      const options = {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          yAxes: {
            ticks: {
              beginAtZero: true
            }
          }
        }
      }

      return (
        <div className="widget">
          <h3>{capitalize(this.props.person)} Feedback</h3>
          <Bar className="link" data={data} width={500} height={300} options={options} ref={this.barGraph} onClick={this.handleClick} />

          <SlidingPane isOpen={infoPanel} title={type && `${capitalize(type)} Events`} width="70%"
            onRequestClose={() => {
              this.setState({
                infoPanel: false,
                type: null
              });
              this.props.history.push(`/analysis/overview${this.props.location.search}`)
            }}>
            <EventsPanel event_ids={json[type]}></EventsPanel>
          </SlidingPane>
        </div>
      );
    }
  }

  state = {
    json: [],
    infoPanel: false,
    type: null,
    loaded: false,
    error: null,
    unauthorized: null,
    invalid_params: false
  };

  static getDerivedStateFromProps(props, state) {
    const validContext = (props.match.params.context === "positive" || props.match.params.context === "neutral" || props.match.params.context === "negative");

    if (state.infoPanel === false && props.match.params.chart === props.person && validContext) {
      return {
        infoPanel: true,
        type: props.match.params.context
      }
    }
    else if (state.infoPanel && (props.match.params.chart !== props.person || validContext === false)) {
      return {
        infoPanel: false,
        type: null
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.match.params.chart === props.person && validContext) {
      return {
        infoPanel: true,
        type: props.match.params.context
      }
    }
    else if (props.history.action === "POP" && (props.match.params.chart !== props.person || validContext === false)) {
      return {
        infoPanel: false,
        type: null
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    if (this.props.date_from !== "" && this.props.date_to !== "") {
      let params = `analysis[person]=${this.props.person}&analysis[date_from]=${this.props.date_from}&analysis[date_to]=${this.props.date_to}&analysis[location]=${this.props.location_id}`

      request('GET', `/v1/a/analysis/feedback_by_people?${params}`, null, () => {this.setState({ unauthorized: true })})
        .then(data => {
          this.setState({ json: data, loaded: true })
        })
        .catch(error => {
          reportErrorToHoneybadger(error)

          this.setState({ error, loaded: true })
        })
    }
    else {
      this.setState({
        invalid_params: true
      })
    }
  }
}

export default withRouter(AnalysisFeedbackByPeople);
