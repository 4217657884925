import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import Page from '../../components/shared/Page';

import UpdateRotaType from '../../components/rota-types/UpdateRotaType';
import CreateRotaType from '../../components/rota-types/CreateRotaType';

import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SlidingPane from "../../components/shared/ScrollableSlidingPane";

class StaffFulfilmentIndex extends Component {
  constructor(props) {
    super(props);

    this.handleNewPanel = this.handleNewPanel.bind(this);
    this.handleEditPanel = this.handleEditPanel.bind(this);
    this.closeEditPanel = this.closeEditPanel.bind(this);
  }

  state = {
    newPanelToggle: false,
    editPanelToggle: false,
    editId: null,
    location_id: null,

    locations: [],

    locationsLoaded: false,
    rotaTypesLoaded: false,
    error: null,
    unauthorized: null
  };

  handleNewPanel(event) {
    const location_id = event.currentTarget.getAttribute('location_id');

    this.setState({
      location_id: location_id,
      newPanelToggle: true
    });
    this.props.history.push('/rota-types/new')
  }

  handleEditPanel(event) {
    const id = event.currentTarget.id;

    this.setState({
      editId: id,
      editPanelToggle: true
    });
    this.props.history.push(`/rota-types/${id}`)
  }

  closeEditPanel() {
    this.setState({
      editPanelToggle: false,
      editId: null
    });
    this.props.history.push('/staff-fulfilment');
    this.componentDidMount();
  }

  backgroundImage(background_image) {
    if (background_image !== null) {
      return (
        `url(${process.env.REACT_APP_ROOT_DOMAIN}/${background_image})`
      );
    }
  }

  backgroundRadial(roles) {
    let hexCodes = roles.map(role => role.color)

    if (hexCodes.length === 1) {
      hexCodes.push("#ffffff")
    }

    return (
      `radial-gradient(ellipse, ${hexCodes.join(", ")}`
    )
  }

  render() {
    const { locations, rota_types, location_id, locationsLoaded, rotaTypesLoaded, error, unauthorized } = this.state;

    if (localStorage.license === "entry") {
      return <Redirect to="/upgrade" />
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (locationsLoaded === false || rotaTypesLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (locations.length === 0) {
      // Insert sales pitch here?
      return (
        <Page title="Staff Fulfilment">
          <div>None of your Locations have Staff Fulfilment access.</div>
        </Page>
      )
    }

    if (locationsLoaded && rotaTypesLoaded) {
      return (
        <div>
          <Page title="Staff Fulfilment">
            <div className="widgets">
              <h3>Select a Location</h3>

              {locations.map((location) => (
                <div className="location-row" key={location.id}>
                  {this.renderLocationBox(location)}

                  {rota_types.filter(rota_type => rota_type.location_id === location.id).map((rota_type) => (
                    this.renderRotaTypeBox(rota_type)
                  ))}

                  {this.renderNewRotaTypeBox(location)}
                </div>
              ))}
            </div>
          </Page>

          <SlidingPane isOpen={this.state.newPanelToggle} title="New Rota Type" width="60%"
            onRequestClose={() => {
              this.setState({ newPanelToggle: false });
              this.props.history.push('/staff-fulfilment');
              this.componentDidMount();
            }}>
            <CreateRotaType location_id={location_id} />
          </SlidingPane>

          <SlidingPane isOpen={this.state.editPanelToggle} title="Edit Rota Type" width="60%"
            onRequestClose={() => {
              this.closeEditPanel()
            }}>
            <UpdateRotaType id={this.state.editId} closeEditPanel={this.closeEditPanel} />
          </SlidingPane>
        </div>
      );
    }
  }

  renderLocationBox(location) {
    return (
      <Link to={`/staff-fulfilment/${location.id}`}>
        <div className="widget locations-component full-background" style={{ backgroundImage: this.backgroundImage(location.background_image) }}>
          <div className="fade">
            <h3 className="title">{location.name}</h3>
          </div>
        </div>
      </Link>
    )
  }

  renderRotaTypeBox(rota_type) {
    return (
      <div className="widget rota-type-component full-background" key={rota_type.id} style={{ background: this.backgroundRadial(rota_type.roles) }}>
        <Link to={`/rota-types/${rota_type.id}`} onClick={this.handleEditPanel} id={rota_type.id} className="edit-cog">
          <FontAwesomeIcon icon={faCog} />
        </Link>
        <Link to={`/staff-fulfilment/${rota_type.location_id}/rotas/${rota_type.id}`} key={rota_type.id} className="hidden-link">
          <div className="fade">
            <h3 className="title">{rota_type.name}</h3>
            {rota_type.roles.map(role => (
              <div key={role.id}>{role.name}</div>
            ))}
          </div>
        </Link>
      </div>
    )
  }

  renderNewRotaTypeBox(location) {
    return (
      <Link to={`/rota-types/new`} onClick={this.handleNewPanel} location_id={location.id} key={location.id}>
        <div className="widget rota-type-component full-background" style={{ backgroundImage: "url(../../plus-icon.jpg)" }}>
          <div className="fade">
            <h3 className="title">New Rota Type</h3>
          </div>
        </div>
      </Link>
    )
  }

  static getDerivedStateFromProps(props, state) {
    if (state.newPanelToggle && props.match.params.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.match.params.id
      }
    }
    else if (state.editPanelToggle && props.match.params.id === undefined) {
      return {
        editPanelToggle: false
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.pathname.endsWith("/staff-fulfilment")) {
      return {
        newPanelToggle: false,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.history.location.pathname.endsWith("/new")) {
      return {
        newPanelToggle: true,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.match.params.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.match.params.id
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // Deep linking
    if (this.props.location.pathname.endsWith("/new") && this.state.newPanelToggle === false) {
      this.setState({
        newPanelToggle: true
      });
    }
    else if (this.props.id !== undefined && this.state.editPanelToggle === false) {
      this.setState({
        editId: this.props.id,
        editPanelToggle: true
      });
    }

    // normal API fetching stuff
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        const filteredLocations = data.filter(location => location.staff_fulfilment)

        localStorage.setItem("staff_fulfilment_access", filteredLocations.length > 0)

        this.setState({ locations: filteredLocations, locationsLoaded: true })
      })
      .catch(error => this.setState({ error, locationsLoaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/rota_types`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ rota_types: data, rotaTypesLoaded: true })
      })
      .catch(error => this.setState({ error, rotaTypesLoaded: true }))
  }
}

export default StaffFulfilmentIndex;
