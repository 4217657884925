import React from 'react';
import { Link } from 'react-router-dom';

import LoggedOutHeader from '../../components/header/LoggedOutHeader';
import SetTitle from '../../components/shared/SetTitle';
import BigLogo from '../../components/header/BigLogo';

const SignUpReturn = () => {
  return (
    <div className="login-page">
      <SetTitle title={"Sign Up Complete"} />
      <LoggedOutHeader />

      <div className="center-container">
        <p className="welcome-to">Welcome to...</p>

        <BigLogo />

        <p>Thank you for signing up!</p>
        <p>We are now generating your Organisation and User account. You should receive an email in few moments with details on how to <Link to="/login">log in</Link>.</p>
        <p>If you have any issues, you can reach out to us on <a rel="noopener noreferrer" href="mailto:support@dreception.com">support@dreception.com</a>.</p>
      </div>
    </div>
  )
};

export default SignUpReturn;
