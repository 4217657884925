import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import CreateFeedbackType from '../../components/feedback_types/CreateFeedbackType';
import UpdateFeedbackType from '../../components/feedback_types/UpdateFeedbackType';

import { hideElementForNonAdmins } from '../../utilities/Forms.js'
import BigTable from '../../utilities/BigTable.js'

import SlidingPane from "../../components/shared/ScrollableSlidingPane";

class ListOfFeedbackTypes extends Component {
  constructor(props) {
    super(props);

    this.handleNewPanel = this.handleNewPanel.bind(this);
    this.handleEditPanel = this.handleEditPanel.bind(this);
    this.closeEditPanel = this.closeEditPanel.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  state = {
    feedbackTypes: [],
    loaded: false,
    error: null,

    editId: null,
    newPanelToggle: false,
    editPanelToggle: false,

    showHidden: false
  };

  handleNewPanel(event) {
    this.setState({newPanelToggle: true});
    this.props.history.push('/configuration/feedback/new')
  }

  handleEditPanel(event) {
    let id = event.currentTarget.id;

    this.setState({editId: id});
    this.setState({editPanelToggle: true});
    this.props.history.push(`/configuration/feedback/${id}`)
  }

  handleCheckbox(event) {
    let name = event.target.name;
    let value = event.target.checked;

    this.setState({[name]: value});
  }

  closeEditPanel() {
    this.setState({
      editPanelToggle: false,
      editId: null
    });
    this.props.history.push('/configuration/feedback');
    this.componentDidMount();
  }

  hiddenFeedback() {
    return this.state.feedbackTypes.filter(feedbackType => feedbackType.hidden === true);
  }

  visibleFeedback() {
    return this.state.feedbackTypes.filter(feedbackType => feedbackType.hidden === false);
  }

  renderHiddenToggle() {
    if (this.hiddenFeedback().length > 0) {
      return (
        <div className="toggle-hidden bottom-margin">
          <input type="checkbox" name="showHidden" id="hide_feedback" checked={this.state.showHidden} onChange={this.handleCheckbox} />
          <label htmlFor="hide_feedback">{this.state.showHidden === true ? "Hide" : "Show"} hidden Feedback Types</label>
        </div>
      )
    }
  }

  render() {
    const { showHidden, loaded, error, unauthorized } = this.state;

    if (localStorage.license === "entry") {
      return <Redirect to="/upgrade" />
    }

    if (unauthorized) {
      return <Redirect to="/login"/> 
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div className="section">
          <h3 className="inline-block">List of Feedback Types</h3>

          <Link to={'/configuration/feedback/new'} onClick={this.handleNewPanel}>
            <div className={`button new-button ${hideElementForNonAdmins()}`}>New Feedback Type</div>
          </Link>

          <BigTable
            url="/configuration/feedback"
            data={this.visibleFeedback()}
            headings={["Name", "Display", "Include in Analysis Graphs", "Hidden"]}
            attributes={["name", "display", "analysis_overview", "hidden"]}
            render_types={["text", "text", "boolean", "boolean"]}
            onClick={this.handleEditPanel}
            classes={"small-bottom-margin"}
          />

          { this.renderHiddenToggle() }

          {this.hiddenFeedback().length > 0 && showHidden ? (
            <BigTable
              data={this.hiddenFeedback()}
              headings={["Name", "Display", "Include in Analysis Graphs", "Hidden"]}
              attributes={["name", "display", "analysis_overview", "hidden"]}
              render_types={["text", "text", "boolean", "boolean"]}
              onClick={this.handleEditPanel}
            />
          ) : null}

          <SlidingPane isOpen={this.state.newPanelToggle} title="New Feedback Type" width="60%"
            onRequestClose={() => {
              this.setState({ newPanelToggle: false });
              this.props.history.push('/configuration/feedback');
              this.componentDidMount();
            }}>
            <CreateFeedbackType />
          </SlidingPane>

          <SlidingPane isOpen={this.state.editPanelToggle} title="Edit Feedback Type" width="60%"
            onRequestClose={() => {
              this.closeEditPanel()
            }}>
            <UpdateFeedbackType id={this.state.editId} closeEditPanel={this.closeEditPanel} updatePopupCallback={this.props.updatePopupCallback} question_id={this.props.question_id} />
          </SlidingPane>

        </div>
      );
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.newPanelToggle && props.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.id
      }
    }
    else if (state.editPanelToggle && props.id === undefined) {
      return {
        editPanelToggle: false
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.pathname.endsWith("/feedback")) {
      return {
        newPanelToggle: false,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.history.location.pathname.endsWith("/feedback/new")) {
      return {
        newPanelToggle: true,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.id
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep linking
    if (this.props.id === undefined && this.props.location.pathname.endsWith("/feedback/new") && this.state.newPanelToggle === false) {
      this.setState({
        newPanelToggle: true
      });
    }
    else if (this.props.id !== undefined && this.state.editPanelToggle === false) {
      this.setState({
        editId: this.props.id,
        editPanelToggle: true
      });
    }

    // normal API fetching stuff
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/organisations/0/feedback_types`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => this.setState({ feedbackTypes: data, loaded: true }))
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default withRouter(ListOfFeedbackTypes);
