import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import CreateRelationship from '../../components/relationships/CreateRelationship';
import UpdateRelationship from '../../components/relationships/UpdateRelationship';
import PopupBox from '../../components/shared/PopupBox';

import { hideElementForNonAdminsAndNonManagers } from '../../utilities/Forms.js'

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SlidingPane from "../../components/shared/ScrollableSlidingPane";

class ListOfRelationships extends Component {

  constructor(props) {
    super(props);

    this.handleDelete = this.handleDelete.bind(this);
    this.handleNewPanel = this.handleNewPanel.bind(this);
    this.handleEditPanel = this.handleEditPanel.bind(this);
    this.closeEditPanel = this.closeEditPanel.bind(this);
  }

  state = {
    relationships: [],

    newPanelToggle: false,
    editPanelToggle: false,
    editID: null,
    editPersonID: null,
    editPersonName: null,
    editRelationshipType: null,
    editFirstPersonRelationshipID: null,
    editSecondPersonRelationshipID: null,
    loaded: false,
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleNewPanel(event) {
    this.setState({newPanelToggle: true});
  }

  handleEditPanel(event) {
    let id = event.currentTarget.id;

    const relationship = this.state.relationships.find(relationship => (relationship.relationship_id === parseInt(id)))

    this.setState({
      editID: id,
      editPersonID: relationship.person_id.toString(),
      editPersonName: relationship.full_name,
      editRelationshipType: relationship.relationship_type,
      editFirstPersonRelationshipID: relationship.id,
      editSecondPersonRelationshipID: relationship.other_person_relationship_id,
      editPanelToggle: true,
    });

    event.stopPropagation();
  }

  closeEditPanel() {
    this.setState({
      editPanelToggle: false,
      editID: null,
      editPersonID: null,
      editPersonName: null,
      editFirstPersonRelationshipID: null,
      editSecondPersonRelationshipID: null,
      editRelationshipType: null,
    });
    this.componentDidMount();
  }

  handleDelete(event) {
    let id = event.currentTarget.id
    let person_relationship_id = event.currentTarget.getAttribute("person_relationship_id")

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'DELETE',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    let bigError = false
    let errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/relationships/${id}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return true;
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        bigError = true
        return response.json()
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (bigError) {
        this.setState({ error: data })
      }
      else if (errorsInResponse) {
        this.setState({ errors: data })
      }
      else {
        // remove relationship we just deleted
        let updated_relationships = this.state.relationships.filter((relationship) => relationship.id.toString() !== person_relationship_id);

        this.setState({ relationships: updated_relationships, errors: "" })
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
    event.stopPropagation();
  }

  emptyRelationships(relationships) {
    if (relationships.length === 0) {

      let text = "No relationships found for this person"

      return (
        <div className="long-tr">
          <div className="long-td">
            { text }
          </div>
        </div>
      )
    }
  }

  render() {
    const { relationships, editID, editPersonID, editPersonName, editFirstPersonRelationshipID, editSecondPersonRelationshipID, editRelationshipType, loaded, unauthorized, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return (
        <div>
          {error["errors"].map((errors, index) => (
            <div className="warning-box" key={index}>{errors}</div>
          ))}
        </div>
      )
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      let person_id = this.props.person_id;
      let person_name = this.props.person_name;

      return (
        <div>
          <h3 className="inline-block">Relationships</h3>
          <PopupBox />

          <Link to={`#`} onClick={this.handleNewPanel}>
            <div className={`button new-button ${hideElementForNonAdminsAndNonManagers()}`}>New Relationship</div>
          </Link>

          <div className="table big-table" data-testid="table">
            <div className="tr heading">
              <div className="th">Name</div>
              <div className="th">Relationship Type</div>
              <div className="th"></div>
            </div>
            {relationships.map((relationship) => (
              <div onClick={this.handleEditPanel} className="tr" key={relationship.id} id={relationship.relationship_id}>
                <Link to={`/people/${relationship.person_id}`} className="td">{relationship.full_name}</Link>
                <div className="td subtle-link link" title="Click here to edit relationship">{relationship.relationship_type}</div>
                <div className={`td center remove-button ${hideElementForNonAdminsAndNonManagers()}`}>
                  <div onClick={this.handleDelete} id={relationship.relationship_id} person_relationship_id={relationship.id}>Remove <FontAwesomeIcon icon={faTimes} /></div>
                </div>
              </div>
            ))}
          </div>
          {this.emptyRelationships(relationships)}

          <SlidingPane isOpen={this.state.newPanelToggle} title="New Relationship" width="50%"
            onRequestClose={() => {
              this.setState({ newPanelToggle: false });
              this.componentDidMount();
            }}>
            <CreateRelationship other_person_name={person_name} other_person_id={person_id} />
          </SlidingPane>

          <SlidingPane isOpen={this.state.editPanelToggle} title="Update Relationship" width="50%"
            onRequestClose={this.closeEditPanel}>
            <UpdateRelationship id={editID} other_person_name={person_name} other_person_id={person_id} person_id={editPersonID} person_name={editPersonName} first_person_relationship_id={editFirstPersonRelationshipID} second_person_relationship_id={editSecondPersonRelationshipID} relationship_type={editRelationshipType} closeEditPanel={this.closeEditPanel} />
          </SlidingPane>
        </div>
      );
    }
  }

  componentDidMount() {
    let person_id = this.props.person_id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/people/${person_id}/relationships`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ relationships: data, loaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default ListOfRelationships;
