import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import UpdateDevice from '../../components/devices/UpdateDevice';
import CreateDevice from '../../components/devices/CreateDevice';
import HelpText from '../../components/help/HelpText';

import { hideElementForNonAdmins } from '../../utilities/Forms.js'

import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SlidingPane from "../../components/shared/ScrollableSlidingPane";

class ListOfDevices extends Component {
  constructor(props) {
    super(props);

    this.handleNewPanel = this.handleNewPanel.bind(this);
    this.handleEditPanel = this.handleEditPanel.bind(this);
    this.closeEditPanel = this.closeEditPanel.bind(this);
  }

  state = {
    devices: [],
    loaded: false,
    error: null,

    editId: null,
    newPanelToggle: false,
    editPanelToggle: false
  };

  handleNewPanel(event) {
    this.setState({newPanelToggle: true});
    this.props.history.push('/configuration/devices/new')
  }

  handleEditPanel(event) {
    let id = event.currentTarget.id;

    this.setState({editId: id});
    this.setState({editPanelToggle: true});
    this.props.history.push(`/configuration/devices/${id}`)
  }

  closeEditPanel() {
    this.setState({
      editPanelToggle: false,
      editId: null
    });
    this.props.history.push('/configuration/devices');
    this.componentDidMount();
  }

  renderOnlineDeviceCount(online_device_count) {
    // show zeroes in red
    if (online_device_count === 0) {
      return <span className="red-text">{online_device_count}</span>
    }

    return online_device_count
  }

  renderPhotoStatus(photo_status) {
    if (photo_status === "no devices") {
      return <span title="No physical devices have been contacted recently, so photo status cannot be obtained." >n/a</span>
    }

    if (photo_status === "ok") {
      return <FontAwesomeIcon icon={faCheckCircle} className="green-text" title="None of the physical devices contacted recently appear to have photo taking issues at the moment." />
    }

    let title = "One or more of the physical devices contacted recently have issues taking photos: "

    if (photo_status.includes("too_many_photos")) {
      title += "too many photos are stored locally"
    }

    if (photo_status.includes("too_many_photos") && photo_status.includes("rejected_photos")) {
      title += " and "
    }

    if (photo_status.includes("rejected_photos"))  {
      title += "our app does not have permission to use the camera"
    }

    title += "."

    return <FontAwesomeIcon icon={faTimes} className="red-text" title={title} />
  }

  render() {
    const { devices, loaded, error, unauthorized } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div>
          <h3 className="inline-block">List of devices</h3>

          <Link to={'/configuration/devices/new'} onClick={this.handleNewPanel}>
            <div className={`button new-button ${hideElementForNonAdmins()}`}>New Device</div>
          </Link>

          <div className="table big-table">
            <div className="tr heading">
              <div className="th">Name</div>
              <div className="th">Last Updated</div>
              <div className="th">Last Contacted</div>
              <div className="th">Location</div>
              <div className="th">Online Devices<HelpText className="th-helptext" page={'device'} section={'online_device_count'} /></div>
              <div className="th">Photo Status<HelpText className="th-helptext" page={'device'} section={'photo_status'} /></div>
            </div>
            {devices.map((device) => (
                <Link to={`/configuration/devices/${device.id}`} onClick={this.handleEditPanel} id={device.id} key={device.id} className="tr">
                  <div className="td">{device.name}</div>
                  <div className="td">{device.last_contacted}</div>
                  <div className="td">{device.last_updated}</div>
                  <div className="td">{device.location}</div>
                  <div className="td center">{this.renderOnlineDeviceCount(device.online_device_count)}</div>
                  <div className="td center" >{this.renderPhotoStatus(device.photo_status)}</div>
                </Link>
            ))}
          </div>

          <SlidingPane isOpen={this.state.newPanelToggle} title="New Device" width="60%"
            onRequestClose={() => {
              this.setState({ newPanelToggle: false });
              this.props.history.push('/configuration/devices');
              this.componentDidMount();
            }}>
            <CreateDevice />
          </SlidingPane>

          <SlidingPane isOpen={this.state.editPanelToggle} title="Edit Device" width="60%"
            onRequestClose={() => {
              this.closeEditPanel()
            }}>
            <UpdateDevice id={this.state.editId} closeEditPanel={this.closeEditPanel} />
          </SlidingPane>

        </div>
      );
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.newPanelToggle && props.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.id
      }
    }
    else if (state.editPanelToggle && props.id === undefined) {
      return {
        editPanelToggle: false
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.pathname.endsWith("/devices")) {
      return {
        newPanelToggle: false,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.history.location.pathname.endsWith("/new")) {
      return {
        newPanelToggle: true,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.id
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep linking
    if (this.props.location.pathname.endsWith("/new") && this.state.newPanelToggle === false) {
      this.setState({
        newPanelToggle: true
      });
    }
    else if (this.props.id !== undefined && this.state.editPanelToggle === false) {
      this.setState({
        editId: this.props.id,
        editPanelToggle: true
      });
    }

    // normal API fetching stuff
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/devices`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => this.setState({ devices: data, loaded: true }))
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default withRouter(ListOfDevices);
