import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Select from 'react-select'

import Header from '../../components/header/Header';
import ListOfPeople from '../../components/people/ListOfPeople';
import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';
import PopupBox from '../../components/shared/PopupBox';
import TypeOfPersonSelect from '../../components/shared/TypeOfPersonSelect';
import { debounce } from '../../utilities/Generic.js'

class PeopleIndex extends Component {
  static TIME_COLUMNS = ["last_login", "updated_at"];

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handlePartialChange = this.handlePartialChange.bind(this);
    this.debouncedHandleSearch = debounce(this.handleChange.bind(this), 500);
  }

  state = {
    location: "",
    type_of_person: "",
    tag_ids: [],
    page: 1,
    sort: "first_name",
    direction: "asc",
    search: "",
    refresh_location: "",
    refresh_type_of_person: "",
    refresh_tag_ids: [],
    refresh_page: 1,
    refresh_sort: "first_name",
    refresh_direction: "asc",
    refresh_search: "",

    locations: [],
    tags: [],
    locationsLoaded: false,
    tagsLoaded: false,
    key: 0
  };

  handleChange(event) {
    let name = event.target.name;
    var value = event.target.value;

    this.setState({
      [name]: value,
      key: Math.random(),
      page: 1
    });

    if (value === "") {
      value = "all"
    }

    let url = {
      type_of_person: this.state.type_of_person === "" ? "all" : this.state.type_of_person,
      location: this.state.location === "" ? "all" : this.state.location,
      tag_ids: this.state.tag_ids.length === 0 ? "all" : this.state.tag_ids,
      sort: this.state.sort,
      direction: this.state.direction,
      search: this.state.search
    }

    url[name] = value

    this.props.history.push(`/people?location=${url.location}&type=${url.type_of_person}&tag_ids=${url.tag_ids}&page=1&sort=${url.sort}&direction=${url.direction}&search=${url.search}`);
  }

  handleMultiSelectChange(data) {
    const fakeEvent = {}
    fakeEvent.target = {}
    fakeEvent.target.name = "tag_ids"
    fakeEvent.target.value = data.map(option => option.value);

    if (data.length === 0) {
      fakeEvent.target.value = "all"
    }

    this.handleChange(fakeEvent)
  }

  handlePreviousPage() {
    let value = parseInt(this.state.page) - 1
    this.setState({
      page: value,
      key: Math.random()
    });

    let type_of_person = this.state.type_of_person === "" ? "all" : this.state.type_of_person
    let location = this.state.location === "" ? "all" : this.state.location
    let sort = this.state.sort
    let direction = this.state.direction
    let search = this.state.search
    let tag_ids = this.state.tag_ids

    this.props.history.push(`/people?location=${location}&type=${type_of_person}&tag_ids=${tag_ids}&page=${value}&sort=${sort}&direction=${direction}&search=${search}`)
  }

  handleNextPage() {
    let value = parseInt(this.state.page) + 1
    this.setState({
      page: value,
      key: Math.random()
    });

    let type_of_person = this.state.type_of_person === "" ? "all" : this.state.type_of_person
    let location = this.state.location === "" ? "all" : this.state.location
    let sort = this.state.sort
    let direction = this.state.direction
    let search = this.state.search
    let tag_ids = this.state.tag_ids

    this.props.history.push(`/people?location=${location}&type=${type_of_person}&tag_ids=${tag_ids}&page=${value}&sort=${sort}&direction=${direction}&search=${search}`)
  }

  handleSortChange(attribute) {
    let sort = this.state.sort
    let direction = this.state.direction
    let search = this.state.search

    if (this.state.sort !== attribute) {
      sort = attribute
      direction = PeopleIndex.TIME_COLUMNS.includes(attribute) ? "desc" : "asc"
      this.setState({
        sort: attribute,
        direction: direction,
        page: 1,
        key: Math.random()
      });
    }
    else if (this.state.direction === "asc") {
      direction = "desc"
      this.setState({
        direction: direction,
        page: 1,
        key: Math.random()
      })
    }
    else {
      direction = "asc"
      this.setState({
        direction: direction,
        page: 1,
        key: Math.random()
      })
    }

    let type_of_person = this.state.type_of_person === "" ? "all" : this.state.type_of_person
    let location = this.state.location === "" ? "all" : this.state.location
    let tag_ids = this.state.tag_ids === "" ? "all" : this.state.tag_ids
    this.props.history.push(`/people?location=${location}&type=${type_of_person}&tag_ids=${tag_ids}&page=1&sort=${sort}&direction=${direction}&search=${search}`)
  }

  handlePartialChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});

    this.debouncedHandleSearch(event)

    // This stops getDerivedStateFromProps using params to override this state change when typing immediately after hitting back/forward
    this.props.history.action = "PUSH"
  }

  render() {
    const { location, type_of_person, tag_ids, page, sort, direction, search, locations, locationsLoaded, tags, tagsLoaded, key, unauthorized, error } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    let id = this.props.match.params.id
    if (locationsLoaded === false || tagsLoaded === false || this.state.key === 0) {
      return (
        <div>
          <SetTitle title={"People"} />
          <Header />

          <div className="main-page">
            <h2 className="page-title">People</h2>
            <OrganisationName />

            <p>Loading ...</p>
          </div>
        </div>
      )
    }

    if (locationsLoaded === true && tagsLoaded && key !== 0) {
      const selectOptions = tags.map(tag => ({ value: tag.id, label: tag.name }))
      const selectedTags = selectOptions.filter(option => tag_ids.includes(option.value))

      return (
        <div>
          <SetTitle title={"People"} />
          <PopupBox />
          <Header />

          <div className="main-page">
            <h2 className="page-title">People</h2>
            <OrganisationName />

            <div className="export-selection">
              <div className="selection-block">
                <label className="column">Location:</label>
                <select name="location" value={location} onChange={this.handleChange} data-testid="select-locations">
                  <option value="">All</option>
                  {locations.map((location) => (
                    <option value={location.id} key={location.id}>{location.name}</option>
                  ))}
                  <option value="none">None</option>
                </select>
              </div>

              <div className="selection-block">
                <label className="column">Type:</label>
                <TypeOfPersonSelect defaultText="All" value={type_of_person} onChange={this.handleChange} />
              </div>

              <div className="selection-block">
                <label className="column">Search by Name:</label>
                <input type="text" name="search" value={search} onChange={this.handlePartialChange} />
              </div>

              <div className="selection-block">
                <label className="column">Filter by Tags:</label>
                <Select onChange={this.handleMultiSelectChange} options={selectOptions} value={selectedTags} isMulti={true} unstyled={true} className="react-select" classNamePrefix="react-select" placeholder={"All"} />
              </div>
            </div>

            <div>
              <ListOfPeople id={id} location_param={location} type_of_person={type_of_person} tag_ids={tag_ids} page={page} sort={sort} direction={direction} search={search} handleNextPage={this.handleNextPage} handlePreviousPage={this.handlePreviousPage} handleSortChange={this.handleSortChange} key={key + 1} />
            </div>
          </div>

        </div>
      );
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.search === "" && props.location.pathname.endsWith("/people")) {
      let location = state.refresh_location === "" ? "all" : state.refresh_location
      let type_of_person = state.refresh_type_of_person === "" ? "all" : state.refresh_type_of_person
      let tag_ids = state.refresh_tag_ids.length === 0 ? "all" : state.refresh_tag_ids
      let page = state.refresh_page
      let sort = state.refresh_sort
      let direction = state.refresh_direction
      let search = state.refresh_search

      props.history.replace(`/people?location=${location}&type=${type_of_person}&tag_ids=${tag_ids}&page=${page}&sort=${sort}&direction=${direction}&search=${search}`);

      return {
        location: state.refresh_location,
        type_of_person: state.refresh_type_of_person,
        tag_ids: state.refresh_tag_ids,
        page: state.refresh_page,
        sort: state.refresh_sort,
        direction: state.refresh_direction,
        search: state.refresh_search,
        key: Math.random()
      }
    }
    else {
      // handle back/forward buttons
      if (props.history.action === "POP" && props.history.location.search !== "") {
        const params = new URLSearchParams(props.history.location.search)
        let location = params.get('location')
        let type_of_person = params.get('type')
        let tag_ids = params.get('tag_ids') || "all"
        let page = params.get('page')
        let sort = params.get('sort')
        let direction = params.get('direction')
        let search = params.get('search')

        return {
          location: location === "all" ? "" : location,
          type_of_person: type_of_person === "all" ? "" : type_of_person,
          tag_ids: tag_ids === "all" ? [] : tag_ids.split(',').map(id => parseInt(id)),
          page: page,
          sort: sort,
          direction: direction,
          search: search,
          key: Math.random()
        }
      }
      else {
        return null
      }
    }
  }

  componentDidMount() {
    // deep link query params
    if (this.props.location.search !== "") {
      const params = new URLSearchParams(this.props.location.search)

      // grab query param or set default value
      var location = params.get('location') || "all"
      var type_of_person = params.get('type') || "all"
      var tag_ids = params.get('tag_ids') || "all"
      var page = params.get('page') || 1
      var sort = params.get('sort') || "first_name"
      var direction = params.get('direction') || "asc"
      var search = params.get('search') || ""

      // set history in case one of the params was missing
      this.props.history.replace(`/people?location=${location}&type=${type_of_person}&tag_ids=${tag_ids}&page=${page}&sort=${sort}&direction=${direction}&search=${search}`);

      this.setState({
        location: location === "all" ? "" : location,
        type_of_person: type_of_person === "all" ? "" : type_of_person,
        tag_ids: tag_ids === "all" ? [] : tag_ids.split(',').map(id => parseInt(id)),
        page: page,
        sort: sort,
        direction: direction,
        search: search,
        key: Math.random()
      });
    }

    // normal API fetching stuff
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ locations: data, locationsLoaded: true, key: Math.random() })
      })
      .catch(error => this.setState({ error, loaded: true }))

    if (localStorage.license === "base") {
      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/tags/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ tags: data, tagsLoaded: true, key: Math.random() })
      })
      .catch(error => this.setState({ error, loaded: true }))
    }
    else {
      this.setState({
        tags: [],
        tagsLoaded: true
      })
    }
  }
}

export default PeopleIndex;
