import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import UpdateImport from '../../components/imports/UpdateImport';
import CreateImport from '../../components/imports/CreateImport';

import { hideElementForNonAdmins } from '../../utilities/Forms.js'

import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SlidingPane from "../../components/shared/ScrollableSlidingPane";

class ListOfImports extends Component {
  constructor(props) {
    super(props);

    this.handleNewPanel = this.handleNewPanel.bind(this);
    this.handleEditPanel = this.handleEditPanel.bind(this);
    this.closeEditPanel = this.closeEditPanel.bind(this);
  }

  state = {
    imports: [],
    loaded: false,
    error: null,

    editId: null,
    newPanelToggle: false,
    editPanelToggle: false,
    previousURL: '/imports'
  };

  handleNewPanel(event) {
    this.setState({
      newPanelToggle: true,
      previousURL: `${this.props.location.pathname}${this.props.location.search}`
    });
    this.props.history.push('/imports/new')
  }

  handleEditPanel(event) {
    let id = event.currentTarget.id;

    this.setState({
      editId: id,
      editPanelToggle: true,
      previousURL: `${this.props.location.pathname}${this.props.location.search}`
    });
    this.props.history.push(`/imports/${id}`)
  }

  closeEditPanel() {
    this.setState({
      editPanelToggle: false,
      editId: null
    });
    this.props.history.push(this.state.previousURL);
    this.componentDidMount();
  }

  emptyImports(imports) {
    if (imports.length === 0) {

      let text = "No imports to show";

      if (this.props.selected_location !== "") {
        text+= " for this Location."
      }

      text+= "."

      return (
        <div className="long-tr">
          <div className="long-td">
            { text }
          </div>
        </div>
      )
    }
  }

  render() {
    const { imports, loaded, error, unauthorized } = this.state;

    if (localStorage.license === "entry") {
      return <Redirect to="/upgrade" />
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div>
          <h3 className="inline-block">List of imports</h3>

          <Link to={'/imports/new'} onClick={this.handleNewPanel}>
            <div className={`button new-button ${hideElementForNonAdmins()}`}>New Import</div>
          </Link>

          <div className="table big-table">
            <div className="tr heading">
              <div className="th">Name</div>
              <div className="th">Location</div>
              <div className="th">Type of Person</div>
              <div className="th">Last Success</div>
              <div className="th">Inactive</div>
              <div className="th">Will Run Automatically</div>
            </div>
            {imports.map((_import) => (
                <Link to={`/imports/${_import.id}`} onClick={this.handleEditPanel} id={_import.id} key={_import.id} className="tr">
                  <div className="td">{_import.name}</div>
                  <div className="td">{_import.location}</div>
                  <div className="td">{_import.type_of_person}</div>
                  <div className="td">{_import.last_success}</div>
                  <div className="td center">{_import.inactive ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}</div>
                  <div className="td center">{_import.will_run_automatically ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}</div>
                </Link>
            ))}
          </div>

          {this.emptyImports(imports)}

          <SlidingPane isOpen={this.state.newPanelToggle} title="New Import" width="60%"
            onRequestClose={() => {
              this.setState({ newPanelToggle: false });
              this.props.history.push(this.state.previousURL);
              this.componentDidMount();
            }}>
            <CreateImport />
          </SlidingPane>

          <SlidingPane isOpen={this.state.editPanelToggle} title="Edit Import" width="60%"
            onRequestClose={() => {
              this.closeEditPanel()
            }}>
            <UpdateImport id={this.state.editId} closeEditPanel={this.closeEditPanel}/>
          </SlidingPane>

        </div>
      );
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.newPanelToggle && props.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.id
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.pathname.endsWith("/imports")) {
      return {
        newPanelToggle: false,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.history.location.pathname.endsWith("/new")) {
      return {
        newPanelToggle: true,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.id
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep linking
    if (this.props.location.pathname.endsWith("/new") && this.state.newPanelToggle === false) {
      this.setState({
        newPanelToggle: true
      });
    }
    else if (this.props.id !== undefined && this.state.editPanelToggle === false) {
      this.setState({
        editId: this.props.id,
        editPanelToggle: true
      });
    }

    // normal API fetching stuff
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/imports?import[location]=${this.props.selected_location}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => this.setState({ imports: data, loaded: true }))
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default withRouter(ListOfImports);
