import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import 'chart.js/auto';
import { Bar, getElementAtEvent } from 'react-chartjs-2';

import EventsPanel from '../../../components/shared/EventsPanel';

import { capitalize } from '../../../utilities/Forms.js'
import { showExcludedFeedbackTypes } from '../../../utilities/Generic.js'

import SlidingPane from "../../../components/shared/ScrollableSlidingPane";

class LocationWeeklyFeedback extends Component {
  constructor(props) {
    super(props);

    this.barGraph = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.openPanel = this.openPanel.bind(this);
  }

  handleClick(event) {
    // do not try to open events panel when charts are not being clicked
    if (getElementAtEvent(this.barGraph.current, event)[0] === undefined) {
      return
    }

    const index = getElementAtEvent(this.barGraph.current, event)[0].index
    this.openPanel(index)
  }

  openPanel(index) {
    let type;
    // eslint-disable-next-line
    switch(index) {
      case 0:
        type = "positive"
        break;
      case 1:
        type = "neutral"
        break;
      case 2:
        type = "negative"
        break;
    }

    this.setState({
      infoPanel: true,
      type: type
    })

    this.props.history.push(`/location/${this.props.id}/weekly-feedback/${type}`)
  }

  render() {
    const { json, loaded, error, unauthorized, infoPanel, type } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      const data = {
        labels: Object.keys(json),
        datasets: [{
          data: [
            json.positive.length,
            json.neutral.length,
            json.negative.length
          ],

          backgroundColor: ['#22ee80', '#eee622', '#ce5252']
        }]
      }

      const options = {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          yAxes: {
            ticks: {
              beginAtZero: true
            }
          }
        }
      }

      return (
        <div className="widget">
          <h3>Weekly feedback chart</h3>
          <Bar className="link" data={data} width={500} height={300} options={options} ref={this.barGraph} onClick={this.handleClick} />
        {showExcludedFeedbackTypes(this)}

          <SlidingPane isOpen={infoPanel} title={type && `${capitalize(type)} Events`} width="70%"
            onRequestClose={() => {
              this.setState({
                infoPanel: false,
                type: null
              });
              this.props.history.push(`/location/${this.props.id}`)
            }}>
            <EventsPanel event_ids={json[type]}></EventsPanel>
          </SlidingPane>
        </div>
      );
    }
  }

  state = {
    json: [],
    infoPanel: false,
    type: null,
    loaded: false,
    error: null,
    unauthorized: null,
    excludedFeedbackTypes: []
  };

  static getDerivedStateFromProps(props, state) {
    const validContext = (props.match.params.context === "positive" || props.match.params.context === "neutral" || props.match.params.context === "negative");

    if (state.infoPanel === false && validContext) {
      return {
        infoPanel: true,
        type: props.match.params.context
      }
    }
    else if (state.infoPanel && validContext === false) {
      return {
        infoPanel: false,
        type: null
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && validContext) {
      return {
        infoPanel: true,
        type: props.match.params.context
      }
    }
    else if (props.history.action === "POP" && validContext === false) {
      return {
        infoPanel: false,
        type: null
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/${id}/weekly_feedback`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ json: data, loaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/feedback_types/excluded_from_analysis`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ excludedFeedbackTypes: data, excludedFeedbackTypesLoaded: true })
      })
      .catch(error => this.setState({ error, excludedFeedbackTypesLoaded: true }))
  }
}

export default withRouter(LocationWeeklyFeedback);
