import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import FeedbackQuestionForm from '../../forms/FeedbackQuestion';

import { hideElementForNonAdmins } from '../../utilities/Forms.js'

import { faCheckCircle, faTimes, faArrowsAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SlidingPane from "../../components/shared/ScrollableSlidingPane";

import { DragDropContext, Draggable  } from 'react-beautiful-dnd';
import Droppable from '../../components/shared/StrictModeDroppable'

import HelpText from '../../components/help/HelpText';

class ListOfFeedbackQuestions extends Component {
  constructor(props) {
    super(props);

    this.handleNewPanel = this.handleNewPanel.bind(this);
    this.handleEditPanel = this.handleEditPanel.bind(this);
    this.closeEditPanel = this.closeEditPanel.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  state = {
    feedbackQuestions: [],
    loaded: false,
    error: null,

    questionId: null,
    newPanelToggle: false,
    editPanelToggle: false
  };

  handleNewPanel(event) {
    this.setState({newPanelToggle: true});
    this.props.history.push(`/configuration/feedback/${this.props.feedback_id}/question/new`)
  }

  handleEditPanel(event) {
    let questionId = event.currentTarget.id;

    this.setState({questionId: questionId});
    this.setState({editPanelToggle: true});
    this.props.history.push(`/configuration/feedback/${this.props.feedback_id}/question/${questionId}`)
  }

  closeEditPanel() {
    this.setState({ editPanelToggle: false });
    this.setState({ questionId: null });
    this.props.history.push(`/configuration/feedback/${this.props.feedback_id}`);
    this.componentDidMount();
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const feedbackQuestions = this.reorder(
      this.state.feedbackQuestions,
      result.source.index,
      result.destination.index
    );

    this.setState({
      feedbackQuestions: feedbackQuestions
    });

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var reorderList = []
    for (var i = 0; i < feedbackQuestions.length; i++) {
      reorderList.push({id: feedbackQuestions[i].id, order: feedbackQuestions[i].order})
    }

    var json = JSON.stringify({
      "feedback_questions": {
        "reorder": reorderList
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/feedback_questions/reorder`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
  }

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    // now, reassign feedbackQuestions.order numbers
    var newResult = []
    for (var i = 0; i < result.length; i++) {
      var feedbackQuestions = result[i]
      feedbackQuestions.order = i + 1

      newResult.push(feedbackQuestions)
    }

    return newResult;
  };

  indexBackground(index) {
    if (index % 2 === 0) {
      return "#eee"
    }
    else {
      return "#fff"
    }
  }

  answerType(answer_type) {
    var output = ""

    if (answer_type === "boolean") {
      output = "Yes/No"
    }
    else if (answer_type === "stars") {
      output = "Stars"
    }
    else if (answer_type === "smileys") {
      output = "Smileys"
    }
    else if (answer_type === "thumbs") {
      output = "Thumbs"
    }
    else if (answer_type === "text") {
      output = "Text"
    }

    return (
      output
    )
  }

  helpSection() {
    if (this.props.disabled) {
      return 'static-questions'
    }
    else {
      return 'questions'
    }
  }

  renderNewButton() {
    if (!this.props.disabled) {
      return (
        <Link to={`/configuration/feedback/${this.props.feedback_id}/question/new`} onClick={this.handleNewPanel} id={this.props.feedback_id}>
          <div className={`button new-button ${hideElementForNonAdmins()}`}>New Feedback Question</div>
        </Link>
      )
    }
  }

  renderFeedbackQuestions() {
    const feedback_id = this.props.feedback_id
    if (this.props.disabled) {
      return (
        <div className="table big-table">
          <div className="tr heading">
            <div className="th">Order</div>
            <div className="th">Name</div>
            <div className="th">Question</div>
            <div className="th">Answer Type</div>
            <div className="th">Hidden</div>
            <div className="th">Has Active Alert</div>
          </div>
          {this.state.feedbackQuestions.map((feedbackQuestion, index) => (
              <Link to={`/configuration/feedback/${feedback_id}/question/${feedbackQuestion.id}`} onClick={this.handleEditPanel} key={feedbackQuestion.id} id={feedbackQuestion.id} feedbackid={feedback_id} className="tr" >
                <div className="td">{feedbackQuestion.order}</div>
                <div className="td">{feedbackQuestion.name}</div>
                <div className="td">{feedbackQuestion.display}</div>
                <div className="td center">{this.answerType(feedbackQuestion.answer_type)}</div>
                <div className="td center">{feedbackQuestion.hidden ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}</div>
                <div className="td center">{feedbackQuestion.has_active_alert ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}</div>
              </Link>
          ))}
        </div>
      )
    }
    else {
      const getItemStyle = (isDragging, draggableStyle, index) => ({
        backgroundColor: isDragging ? "lightgreen" : this.indexBackground(index),
        ...draggableStyle
      });

      return (
        <DragDropContext onDragEnd={this.onDragEnd} className="table big-table">
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} className="drag-and-drop" ref={provided.innerRef} >
                <div className="row seven-columns heading">
                  <div className="th"></div>
                  <div className="th">Order</div>
                  <div className="th">Name</div>
                  <div className="th">Question</div>
                  <div className="th">Answer Type</div>
                  <div className="th">Hidden</div>
                  <div className="th">Has Active Alert</div>
                </div>

                {this.state.feedbackQuestions.map((feedbackQuestion, index) => (
                  <Draggable key={`key-${feedbackQuestion.id}`} draggableId={`key-${feedbackQuestion.id}`} index={index}>
                    {(provided, snapshot) => {
                      if (snapshot.isDragging) {
                        provided.draggableProps.style.left = "32px"
                      }

                      return (
                        <Link to={`/configuration/feedback/${feedback_id}/question/${feedbackQuestion.id}`} onClick={this.handleEditPanel} id={feedbackQuestion.id} feedbackid={feedback_id} className="row seven-columns" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, index)} >
                          <div className="td"><FontAwesomeIcon icon={faArrowsAlt} /></div>
                          <div className="td">{feedbackQuestion.order}</div>
                          <div className="td">{feedbackQuestion.name}</div>
                          <div className="td">{feedbackQuestion.display}</div>
                          <div className="td center">{this.answerType(feedbackQuestion.answer_type)}</div>
                          <div className="td center">{feedbackQuestion.hidden ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}</div>
                          <div className="td center">{feedbackQuestion.has_active_alert ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}</div>
                        </Link>
                      )
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )
    }
  }

  render() {
    const { feedbackQuestions, loaded, error, unauthorized } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      let feedback_id = this.props.feedback_id;

      return (
        <div className="section">
          <HelpText page={'feedbackType'} section={this.helpSection()} />
          <h3 className="inline-block">List of Feedback Questions</h3>

          {this.renderNewButton()}
          <div className="seperator"> </div>
          {this.renderFeedbackQuestions()}

          <SlidingPane isOpen={this.state.newPanelToggle} title="New Feedback Question" width="55%"
            onRequestClose={() => {
              this.setState({ newPanelToggle: false });
              this.props.history.push(`/configuration/feedback/${feedback_id}`);
              this.componentDidMount();
            }}>
            <FeedbackQuestionForm mode="create" feedback_type_id={this.props.feedback_id} order={feedbackQuestions.length + 1} />
          </SlidingPane>

          <SlidingPane isOpen={this.state.editPanelToggle} title="Edit Feedback Question" width="55%"
            onRequestClose={() => {
              this.closeEditPanel()
            }}>
            <FeedbackQuestionForm id={this.state.questionId} closePanel={this.closeEditPanel} disabled={this.props.disabled} />
          </SlidingPane>

        </div>
      );
    }
  }

  static getDerivedStateFromProps(props, state) {
    let question_id = props.match.params.question_id
    if (state.newPanelToggle && question_id !== null && question_id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        questionId: question_id
      }
    }
    if (state.editPanelToggle && (question_id === null || question_id === undefined)) {
      return {
        editPanelToggle: false
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.pathname.endsWith(`/feedback/${props.feedback_id}`)) {
      return {
        newPanelToggle: false,
        editPanelToggle: false,
        questionId: null
      }
    }
    else if (props.history.action === "POP" && props.history.location.pathname.endsWith("/question/new")) {
      return {
        newPanelToggle: true,
        editPanelToggle: false,
        questionId: null
      }
    }
    else if (props.history.action === "POP" && question_id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        questionId: question_id
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep linking
    if (this.props.location.pathname.endsWith("question/new") && this.state.newPanelToggle === false) {
      this.setState({
        newPanelToggle: true
      });
    }
    else if (this.props.question_id !== null && this.props.question_id !== undefined && this.state.editPanelToggle === false) {
      this.setState({
        questionId: this.props.question_id,
        editPanelToggle: true
      });
    }

    // normal API fetching stuff
    let feedback_id = this.props.feedback_id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/feedback_types/${feedback_id}/feedback_questions`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => this.setState({ feedbackQuestions: data, loaded: true }))
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default withRouter(ListOfFeedbackQuestions);
