import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Select from 'react-select'

import { request, reportErrorToHoneybadger } from '../../helpers/Requests'

import Header from '../../components/header/Header';
import VisitorsExport from '../../components/exports/Visitors';

import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';
import TypeOfPersonSelect from '../../components/shared/TypeOfPersonSelect';
import { debounce, optionalParam, buildParams, formatDate } from '../../utilities/Generic.js'

class ExportsVisitors extends Component {
  static TIME_COLUMNS = ["check_in", "check_out"];

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleVisitedMultiSelectChange = this.handleVisitedMultiSelectChange.bind(this);
    this.handleVisitorMultiSelectChange = this.handleVisitorMultiSelectChange.bind(this);
    this.handleMultiCheckbox = this.handleMultiCheckbox.bind(this);
    this.handleDurationCheckbox = this.handleDurationCheckbox.bind(this);
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handlePartialChange = this.handlePartialChange.bind(this);
    this.debouncedHandleSearch = debounce(this.handleSearch.bind(this), 500);
  }

  state = {
    date_from: null,
    date_to: null,
    location: "",
    visited_type_of_person: "",
    visitor_type_of_person: "",
    custom_attribute_ids: [],
    visited_tag_ids: [],
    visitor_tag_ids: [],
    showDuration: false,
    visited_person_search: "",
    visitor_person_search: "",
    page: 1,
    sort: "default",
    direction: "desc",

    refresh_date_from: formatDate(new Date(), 7),
    refresh_date_to: formatDate(new Date(), 0),
    refresh_location: "",
    refresh_visited_type_of_person: "",
    refresh_visitor_type_of_person: "",
    refresh_visited_tag_ids: [],
    refresh_visitor_tag_ids: [],
    refresh_visited_person_search: "",
    refresh_visitor_person_search: "",
    refresh_page: 1,
    refresh_sort: "default",
    refresh_direction: "desc",

    locations: [],
    custom_attributes: [],
    tags: [],
    locationsLoaded: false,
    customAttributesLoaded: false,
    tagsLoaded: false,
    key: 0
  };

  paramsWithOverride(overrideObject = {}) {

    let date_from = overrideObject.date_from || this.state.date_from
    let date_to = overrideObject.date_to || this.state.date_to
    let location = overrideObject.location || this.state.location
    let visited_type_of_person = overrideObject.visited_type_of_person || this.state.visited_type_of_person
    let visitor_type_of_person = overrideObject.visitor_type_of_person || this.state.visitor_type_of_person
    let visited_tag_ids = overrideObject.visited_tag_ids || this.state.visited_tag_ids
    let visitor_tag_ids = overrideObject.visitor_tag_ids || this.state.visitor_tag_ids
    let visited_person_search = overrideObject.visited_person_search || this.state.visited_person_search
    let visitor_person_search = overrideObject.visitor_person_search || this.state.visitor_person_search
    let showDuration = overrideObject.showDuration || this.state.showDuration
    let custom_attribute_ids = overrideObject.custom_attribute_ids || this.state.custom_attribute_ids
    let page = overrideObject.page || this.state.page
    let sort = overrideObject.sort || this.state.sort
    let direction = overrideObject.direction || this.state.direction

    const params = buildParams(
      `from=${date_from}&to=${date_to}`,
      optionalParam("location", location),
      optionalParam("visited_type", visited_type_of_person),
      optionalParam("visitor_type", visitor_type_of_person),
      optionalParam("visited_tag_ids", visited_tag_ids),
      optionalParam("visitor_tag_ids", visitor_tag_ids),
      optionalParam("visited_person_search", visited_person_search),
      optionalParam("visitor_person_search", visitor_person_search),
      optionalParam("duration", showDuration),
      this.customAttributeHistoryBuilder(custom_attribute_ids),
      `page=${page}&sort=${sort}&direction=${direction}`
    )

    return params
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({
      [name]: value,
      key: Math.random(),
      page: 1
    });

    const params = this.paramsWithOverride({
      [name]: value,
      page: 1
    })

    this.props.history.push(`/exports/visitors?${params}`);
  }

  handleVisitedMultiSelectChange(data) {
    const fakeEvent = {}
    fakeEvent.target = {}
    fakeEvent.target.name = "visited_tag_ids"
    fakeEvent.target.value = data.map(option => option.value);

    this.handleChange(fakeEvent)
  }

  handleVisitorMultiSelectChange(data) {
    const fakeEvent = {}
    fakeEvent.target = {}
    fakeEvent.target.name = "visitor_tag_ids"
    fakeEvent.target.value = data.map(option => option.value);

    this.handleChange(fakeEvent)
  }

  handleMultiCheckbox(event) {
    const name = parseInt(event.target.name);
    const value = event.target.checked;

    const custom_attribute_ids = this.state.custom_attribute_ids
    if (value === true) {
      if (!custom_attribute_ids.includes(name)) {
        custom_attribute_ids.push(name)
      }
    }
    else if (value === false) {
      if (custom_attribute_ids.includes(name)) {
        const index = custom_attribute_ids.indexOf(name)
        custom_attribute_ids.splice(index, 1)
      }
    }

    this.setState({
      custom_attribute_ids: custom_attribute_ids,
      key: Math.random()
    });


    const params = this.paramsWithOverride({custom_attribute_ids})

    this.props.history.push(`/exports/visitors?${params}`);
  }

  customAttributeHistoryBuilder(custom_attribute_ids) {
    if (custom_attribute_ids.length > 0) {
      return `custom_attribute_ids=${custom_attribute_ids}`
    }
    else return ""
  }

  handleDurationCheckbox(event) {
    let name = event.target.name;
    let value = event.target.checked;

    this.setState({[name]: value});

    const params = this.paramsWithOverride({ [name]: value })

    this.props.history.push(`/exports/visitors?${params}`);
  }

  handlePreviousPage() {
    let value = parseInt(this.state.page) - 1
    this.setState({
      page: value,
      key: Math.random()
    });

    const params = this.paramsWithOverride({ page: value })

    this.props.history.push(`/exports/visitors?${params}`);
  }

  handleNextPage() {
    let value = parseInt(this.state.page) + 1
    this.setState({
      page: value,
      key: Math.random()
    });

    const params = this.paramsWithOverride({ page: value })

    this.props.history.push(`/exports/visitors?${params}`);
  }

  handleSortChange(attribute) {
    let sort = this.state.sort
    let direction = this.state.direction

    if (this.state.sort !== attribute) {
      sort = attribute
      direction = ExportsVisitors.TIME_COLUMNS.includes(attribute) ? "desc" : "asc"
      this.setState({
        sort: attribute,
        direction: direction,
        page: 1,
        key: Math.random()
      });
    }
    else if (this.state.direction === "asc") {
      direction = "desc"
      this.setState({
        direction: direction,
        page: 1,
        key: Math.random()
      })
    }
    else {
      direction = "asc"
      this.setState({
        direction: direction,
        page: 1,
        key: Math.random()
      })
    }

    const params = this.paramsWithOverride({ sort, direction, page: 1 })

    this.props.history.push(`/exports/visitors?${params}`);
  }

  handlePartialChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});

    this.debouncedHandleSearch()

    // This stops getDerivedStateFromProps using params to override this state change when typing immediately after hitting back/forward
    this.props.history.action = "PUSH"
  }

  handleSearch() {
    this.setState({
      page: 1,
      key: Math.random()
    })

    const params = this.paramsWithOverride({ page: 1 })

    this.props.history.push(`/exports/visitors?${params}`);
  }

  renderCustomAttributes() {
    const custom_attributes = this.state.custom_attributes
    const custom_attribute_ids = this.state.custom_attribute_ids

    if (custom_attributes.length > 0 && localStorage.license === "base") {
      return (
        <div className="selection-block">
          Display Custom Attributes:
          {custom_attributes.map((custom_attribute) => (
            <div className="selection-block right-label" key={"custom_attribute_"+custom_attribute.id}>
              <input className="clickable" type="checkbox" id={custom_attribute.id} name={custom_attribute.id} key={"custom_attribute_"+custom_attribute.id} checked={custom_attribute_ids.includes(custom_attribute.id)} onChange={this.handleMultiCheckbox} />
              <label className="clickable" htmlFor={custom_attribute.id} key={custom_attribute.name}>{custom_attribute.name}</label>
            </div>
          ))}
        </div>
      )
    }
  }

  render() {
    const { date_from, date_to, location, visited_type_of_person, visitor_type_of_person, visited_tag_ids, visitor_tag_ids, custom_attribute_ids, visitor_person_search, visited_person_search, locations, custom_attributes, tags, locationsLoaded, customAttributesLoaded, tagsLoaded, unauthorized, error, page, sort, direction, showDuration } = this.state;
    const { match: { params } } = this.props;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (locationsLoaded === false || tagsLoaded === false || customAttributesLoaded === false) {
      return (
        <div>
          <SetTitle title={"Export Visitors | Exports"} />
          <Header />

          <div className="main-page">
            <h2 className="page-title">Export Visitors</h2>
            <OrganisationName />

            <p>Loading ...</p>
          </div>
        </div>
      )
    }

    const selectOptions = tags.map(tag => ({ value: tag.id, label: tag.name }))
    const selectedVisitedTags = selectOptions.filter(option => visited_tag_ids.includes(option.value))
    const selectedVisitorTags = selectOptions.filter(option => visitor_tag_ids.includes(option.value))

    return (
      <div>
        <SetTitle title={"Export Visitors | Exports"} />
        <Header />

        <div className="main-page">
          <h2 className="page-title">Export Visitors</h2>
          <OrganisationName />


          <div className="export-selection">
            <div className="selection-block">
              <label className="column">Date from:</label>
              <input className="column" type="date" name="date_from" value={date_from} onChange={this.handleChange} />
            </div>

            <div className="selection-block">
              <label className="column">Date to:</label>
              <input className="column" type="date" name="date_to" value={date_to} onChange={this.handleChange} />
            </div>

            <div className="selection-block">
              <label className="column">Location:</label>
              <select name="location" value={location} onChange={this.handleChange} data-testid="select-locations">
                <option value="">All</option>
                {locations.map((location) => (
                  <option value={location.id} key={location.id}>{location.name}</option>
                ))}
              </select>
            </div>

            <div className="selection-block">
              <div className="selection-block">
                <label className="column">Visited Person Type:</label>
                <TypeOfPersonSelect name="visited_type_of_person" defaultText="All" value={visited_type_of_person} onChange={this.handleChange} />
              </div>

              <div className="selection-block">
                <label className="column">Filter Visited People by Tags:</label>
                <Select name="visited_tag_ids" onChange={this.handleVisitedMultiSelectChange} options={selectOptions} value={selectedVisitedTags} isMulti={true} unstyled={true} className="react-select" classNamePrefix="react-select" placeholder={"All"} />
              </div>

              <div className="selection-block">
                <label className="column">Search by Visited Person:</label>
                <input type="text" name="visited_person_search" value={visited_person_search} onChange={this.handlePartialChange} />
              </div>
            </div>

            <div className="selection-block">
              <div className="selection-block">
                <label className="column">Visitor Person Type:</label>
                <TypeOfPersonSelect name="visitor_type_of_person" defaultText="All" value={visitor_type_of_person} onChange={this.handleChange} />
              </div>

              <div className="selection-block">
                <label className="column">Filter Visitor People by Tags:</label>
                <Select name="visitor_tag_ids" onChange={this.handleVisitorMultiSelectChange} options={selectOptions} value={selectedVisitorTags} isMulti={true} unstyled={true} className="react-select" classNamePrefix="react-select" placeholder={"All"} />
              </div>

              <div className="selection-block">
                <label className="column">Search by Visitor Person:</label>
                <input type="text" name="visitor_person_search" value={visitor_person_search} onChange={this.handlePartialChange} />
              </div>
            </div>

            <div className="selection-block">
              <label className="column checkbox-label" htmlFor="showDuration">Include durations:</label>
              <input className="column" type="checkbox" name="showDuration" id="showDuration" checked={showDuration} onChange={this.handleDurationCheckbox} />
            </div>

            {this.renderCustomAttributes()}
          </div>

          <VisitorsExport date_from={date_from} date_to={date_to} selected_location={location} visited_type_of_person={visited_type_of_person} visitor_type_of_person={visitor_type_of_person} visited_tag_ids={visited_tag_ids} visitor_tag_ids={visitor_tag_ids} custom_attribute_ids={custom_attribute_ids} custom_attributes={custom_attributes} key={this.state.key + 1} id={params.id} visitor_person_search={visitor_person_search} visited_person_search={visited_person_search} page={page} handleNextPage={this.handleNextPage} handlePreviousPage={this.handlePreviousPage} showDuration={showDuration} sort={sort} direction={direction} handleSortChange={this.handleSortChange} />

        </div>

      </div>
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.search === "" && props.location.pathname.endsWith("/visitors")) {
      props.history.replace(`/exports/visitors?from=${state.refresh_date_from}&to=${state.refresh_date_to}&page=${state.refresh_page}&sort=${state.refresh_sort}&direction=${state.refresh_direction}`);

      return {
        date_from: state.refresh_date_from,
        date_to: state.refresh_date_to,
        location: state.refresh_location,
        visited_type_of_person: state.refresh_visited_type_of_person,
        visitor_type_of_person: state.refresh_visitor_type_of_person,
        visited_tag_ids: state.refresh_visited_tag_ids,
        visitor_tag_ids: state.refresh_visitor_tag_ids,
        visited_person_search: state.refresh_visited_person_search,
        visitor_person_search: state.refresh_visitor_person_search,
        custom_attribute_ids: [],
        page: state.refresh_page,
        sort: state.refresh_sort,
        direction: state.refresh_direction,
        key: Math.random()
      }
    }
    else {
      // handle back/forward buttons
      if (props.history.action === "POP" && props.history.location.search !== "") {
        const params = new URLSearchParams(props.history.location.search)
        let date_from = params.get('from')
        let date_to = params.get('to')
        let location = params.get('location') || ""
        let visited_type_of_person = params.get('visited_type') || ""
        let visitor_type_of_person = params.get('visitor_type') || ""
        let visited_tag_ids = params.get('visited_tag_ids')?.split(",").map(id => parseInt(id)) || []
        let visitor_tag_ids = params.get('visitor_tag_ids')?.split(",").map(id => parseInt(id)) || []
        let visited_person_search = params.get('visited_person_search') || ""
        let visitor_person_search = params.get('visitor_person_search') || ""
        let custom_attribute_ids = params.get('custom_attribute_ids')?.split(",").map(id => parseInt(id)) || []
        let page = params.get('page') || state.refresh_page
        let sort = params.get('sort') || state.refresh_sort
        let direction = params.get('direction') || state.refresh_direction
        let showDuration = params.get('duration') === null ? false : true

        return {
          date_from: date_from,
          date_to: date_to,
          location: location,
          visited_type_of_person: visited_type_of_person,
          visitor_type_of_person: visitor_type_of_person,
          visited_tag_ids: visited_tag_ids,
          visitor_tag_ids: visitor_tag_ids,
          visited_person_search: visited_person_search,
          visitor_person_search: visitor_person_search,
          custom_attribute_ids: custom_attribute_ids,
          page: page,
          sort: sort,
          direction: direction,
          showDuration: showDuration,
          key: Math.random()
        }
      }
      else {
        return null
      }
    }
  }

  componentDidMount() {
    // deep link params
    if (this.props.location.search !== "") {
      const params = new URLSearchParams(this.props.location.search)

      // grab query param or set default value
      const date_from = params.get('from') || formatDate(new Date(), 7)
      const date_to = params.get('to') || formatDate(new Date(), 0)
      const location = params.get('location') || ""
      const visited_type_of_person = params.get('visited_type') || ""
      const visitor_type_of_person = params.get('visitor_type') || ""
      const visited_tag_ids = params.get('visited_tag_ids')?.split(",").map(id => parseInt(id)) || []
      const visitor_tag_ids = params.get('visitor_tag_ids')?.split(",").map(id => parseInt(id)) || []
      const visited_person_search = params.get('visited_person_search') || ""
      const visitor_person_search = params.get('visitor_person_search') || ""
      const custom_attribute_ids = params.get('custom_attribute_ids')?.split(",").map(id => parseInt(id)) || []
      const showDuration = params.get('duration') === null ? false : true

      const page = params.get('page') || 1
      const sort = params.get('sort') || "default";
      const direction = params.get('direction') || "desc";

      // set history in case one of the params was missing
      const builtParams = buildParams(
        `from=${date_from}&to=${date_to}`,
        optionalParam("location", location),
        optionalParam("visited_type", visited_type_of_person),
        optionalParam("visitor_type", visitor_type_of_person),
        optionalParam("visited_tag_ids", visited_tag_ids),
        optionalParam("visitor_tag_ids", visitor_tag_ids),
        optionalParam("visited_person_search", visited_person_search),
        optionalParam("visitor_person_search", visitor_person_search),
        optionalParam("duration", showDuration),
        this.customAttributeHistoryBuilder(custom_attribute_ids),
        `page=${page}&sort=${sort}&direction=${direction}`
      )

      this.props.history.replace(`/exports/visitors?${builtParams}`);

      this.setState({
        date_from: date_from,
        date_to: date_to,
        location: location,
        visited_type_of_person: visited_type_of_person,
        visitor_type_of_person: visitor_type_of_person,
        visited_tag_ids: visited_tag_ids,
        visitor_tag_ids: visitor_tag_ids,
        visited_person_search: visited_person_search,
        visitor_person_search: visitor_person_search,
        custom_attribute_ids: custom_attribute_ids,
        page: page,
        sort: sort,
        showDuration: showDuration,
        key: Math.random()
      });
    }

    // populate search boxes with default params when deep linking to the ViewEvent panel
    if (this.props.location.search === "" && !this.props.location.pathname.endsWith("/visitors")) {
      this.setState({
        date_from: this.state.refresh_date_from,
        date_to: this.state.refresh_date_to,
        location: this.state.refresh_location,
        visited_type_of_person: this.state.refresh_visited_type_of_person,
        visitor_type_of_person: this.state.refresh_visitor_type_of_person,
        visited_tag_ids: this.state.refresh_visited_tag_ids,
        visitor_tag_ids: this.state.refresh_visitor_tag_ids
      })
    }

    request('GET', `/v1/a/locations/names_and_ids`, null, () => {this.setState({ unauthorized: true })})
      .then(data => {
        this.setState({ locations: data, locationsLoaded: true })
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        this.setState({ error, locationsLoaded: true })
      })

    request('GET', `/v1/a/custom_attributes/names_and_ids`, null, () => {this.setState({ unauthorized: true })})
      .then(data => {
        this.setState({ custom_attributes: data, customAttributesLoaded: true })
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        this.setState({ error, customAttributesLoaded: true })
      })

    request('GET', `/v1/a/tags/names_and_ids`, null, () => {this.setState({ unauthorized: true })})
      .then(data => {
      this.setState({ tags: data, tagsLoaded: true })
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        this.setState({ error, tagsLoaded: true })
      })
  }
}

export default ExportsVisitors;
