import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import 'chart.js/auto';
import { Bar, getElementAtEvent } from 'react-chartjs-2';

import { request, reportErrorToHoneybadger } from '../../../helpers/Requests'

import EventsPanel from '../../../components/shared/EventsPanel';

import { capitalize } from '../../../utilities/Forms.js'

import SlidingPane from "../../../components/shared/ScrollableSlidingPane";

class AnalysisFeedbackByLocation extends Component {
  constructor(props) {
    super(props);

    this.barGraph = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.openPanel = this.openPanel.bind(this);
  }

  handleClick(event) {
    // do not try to open events panel when charts are not being clicked
    if (getElementAtEvent(this.barGraph.current, event)[0] === undefined) {
      return
    }

    const locationIndex = getElementAtEvent(this.barGraph.current, event)[0].index
    const typeIndex = getElementAtEvent(this.barGraph.current, event)[0].datasetIndex
    this.openPanel(locationIndex, typeIndex)
  }

  openPanel(locationIndex, typeIndex) {
    let type;
    // eslint-disable-next-line
    switch(typeIndex) {
      case 0:
        type = "positive"
        break;
      case 1:
        type = "neutral"
        break;
      case 2:
        type = "negative"
        break;
    }

    this.setState({
      infoPanel: true,
      locationIndex: locationIndex,
      type: type
    })
    this.props.history.push(`/analysis/overview/location${locationIndex + 1}/${type}${this.props.location.search}`)
  }

  render() {
    const { json, loaded, error, unauthorized, invalid_params, infoPanel, type, locationIndex } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (invalid_params === true) {
      return <p>Waiting for valid date range ...</p>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {

      const data = {
        labels: json.map((array) => ( array.location )),
        datasets: [{
          label: "Positive",
          data: json.map((array) => ( array.positive.length )),
          backgroundColor: '#22ee80'
        }, {
          label: "Neutral",
          data: json.map((array) => ( array.neutral.length )),
          backgroundColor: '#eee622'
        }, {
          label: "Negative",
          data: json.map((array) => ( array.negative.length )),
          backgroundColor: '#ce5252'
        }]
      }

      const options = {
        tooltips: {
          mode: 'index',
          intersect: false
        },
        scales: {
          yAxes: {
            stacked: true,
            ticks: {
              beginAtZero: true,
            }
          },
          xAxes: {
            stacked: true,
          }
        }
      }

      return (
        <div className="widget">
          <h3>Feedback by Location</h3>
          <Bar className="link" data={data} width={500} height={300} options={options} ref={this.barGraph} onClick={this.handleClick} />

          <SlidingPane isOpen={infoPanel} title={type && `${capitalize(type)} Events at ${json[locationIndex] && json[locationIndex]["location"]}`} width="70%"
            onRequestClose={() => {
              this.setState({
                infoPanel: false,
                type: null
              });
              this.props.history.push(`/analysis/overview${this.props.location.search}`)
            }}>
            <EventsPanel event_ids={json[locationIndex] && json[locationIndex][type]}></EventsPanel>
          </SlidingPane>
        </div>
      );
    }
  }

  state = {
    json: [],
    infoPanel: false,
    locationIndex: null,
    type: null,
    loaded: false,
    error: null,
    unauthorized: null,
    invalid_params: false
  };

  static getDerivedStateFromProps(props, state) {
    const validContext = (props.match.params.context === "positive" || props.match.params.context === "neutral" || props.match.params.context === "negative");

    if (state.infoPanel === false && props.match.params.chart !== undefined && props.match.params.chart.startsWith("location") && validContext) {
      return {
        infoPanel: true,
        locationIndex: props.match.params.chart.slice(8) - 1,
        type: props.match.params.context
      }
    }
    else if (state.infoPanel && (props.match.params.chart === undefined || !props.match.params.chart.startsWith("location") || validContext === false)) {
      return {
        infoPanel: false,
        locationIndex: null,
        type: null
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.match.params.chart !== undefined && props.match.params.chart.startsWith("location") && validContext) {
      return {
        infoPanel: true,
        locationIndex: props.match.params.chart.slice(8) - 1,
        type: props.match.params.context
      }
    }
    else if (props.history.action === "POP" && (props.match.params.chart === undefined || !props.match.params.chart.startsWith("location") || validContext === false)) {
      return {
        infoPanel: false,
        locationIndex: null,
        type: null
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    if (this.props.date_from !== "" && this.props.date_to !== "") {
      let params = `analysis[date_from]=${this.props.date_from}&analysis[date_to]=${this.props.date_to}`

      request('GET', `/v1/a/analysis/feedback_by_location?${params}`, null, () => {this.setState({ unauthorized: true })})
        .then(data => {
          this.setState({ json: data, loaded: true })
        })
        .catch(error => {
          reportErrorToHoneybadger(error)

          this.setState({ error, loaded: true })
        })
    }
    else {
      this.setState({
        invalid_params: true
      })
    }
  }
}

export default withRouter(AnalysisFeedbackByLocation);
