import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import Select from 'react-select'

import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';
import NoticeBox from '../../components/shared/NoticeBox';
import Audits from '../../components/shared/Audits';
import TypeOfPersonSelect from '../../components/shared/TypeOfPersonSelect';

import { adminUser, capitalize, renderErrorWarning } from '../../utilities/Forms.js'
import { hideResidentsCheck, TYPE_OF_PEOPLE } from '../../utilities/Generic.js'

class UpdateProfile extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleMultiCheckbox = this.handleMultiCheckbox.bind(this);
    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    name: "",
    display_name: "",
    type_of_person: "",
    first_name_called: "",
    last_name_called: "",
    email_called: "",
    phone_called: "",

    first_name_radio: "",
    last_name_radio: "",
    email_radio: "",
    phone_radio: "",

    arriving_photo_required: "never",
    leaving_photo_required: "never",

    send_email_on_check_in: false,
    send_email_on_check_out: false,
    text_on_select: "",
    text_on_check_in: "",
    text_on_check_out: "",

    information_on_check_in: "",
    information_on_check_out: "",
    check_in_info_confirmation_needed: false,
    check_out_info_confirmation_needed: false,

    text_emailed_on_check_in: "",
    text_emailed_on_check_out: "",
    auto_checkout_time: "",
    auto_checkout_interval: 0,
    hidden: false,
    covid_pass_check_required: false,
    covid_pass_alert_email: "",
    ask_visiting_people: "optional",
    visitable_types_of_people: [],
    disable_auto_create: false,
    arrival_email: "",

    feedback_type_ids: [],
    device_ids: [],
    custom_attribute_ids: [],
    auto_tag_ids: [],

    feedbackTypes: [],
    devices: [],
    customAttributes: [],

    hideResidents: hideResidentsCheck(),

    loaded: false,
    devicesLoaded: false,
    feedbackTypesLoaded: false,
    customAttributesLoaded: false,
    tagsLoaded: false,
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({[name]: value});
  }

  handleCheckbox(event) {
    const name = event.target.name;
    const value = event.target.checked;

    this.setState({[name]: value});
  }

  handleMultiCheckbox(event, targetKey, ids = true) {
    const name = ids ? parseInt(event.target.name) : event.target.name;
    const value = event.target.checked;

    let array = this.state[targetKey]

    if (value === true) {
      if (!array.includes(name)) {
        array.push(name)
      }
    }
    else if (value === false) {
      if (array.includes(name)) {
        let index = array.indexOf(name)
        array.splice(index, 1)
      }
    }

    this.setState({[targetKey]: array});
  }

  handleMultiSelectChange(data) {
    const fakeEvent = {}
    fakeEvent.target = {}
    fakeEvent.target.name = "auto_tag_ids"
    fakeEvent.target.value = data.map(option => option.value);

    this.handleChange(fakeEvent)
  }

  convertToRadio(used, required) {
    if (used === true && required === true) {
      return "required"
    }
    else if (used === true && required === false) {
      return "optional"
    }
    else {
      return ""
    }
  }

  convertUsedFromRadio(radioValue) {
    return radioValue === "" ? false : true
  }

  convertRequiredFromRadio(radioValue) {
    return radioValue === "required" ? true : false
  }

  handleSubmit(event) {
    let id = this.props.id;

    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    let firstNameUsed = this.convertUsedFromRadio(this.state.first_name_radio)
    let secondNameUsed = this.convertUsedFromRadio(this.state.last_name_radio)
    let emailUsed = this.convertUsedFromRadio(this.state.email_radio)
    let phoneUsed = this.convertUsedFromRadio(this.state.phone_radio)

    let firstNameRequired = this.convertRequiredFromRadio(this.state.first_name_radio)
    let secondNameRequired = this.convertRequiredFromRadio(this.state.last_name_radio)
    let emailRequired = this.convertRequiredFromRadio(this.state.email_radio)
    let phoneRequired = this.convertRequiredFromRadio(this.state.phone_radio)

    const json = JSON.stringify({
      "profile": {
        "name": this.state.name,
        "display_name": this.state.display_name,
        "type_of_person": this.state.type_of_person,
        "first_name_called": this.state.first_name_called,
        "last_name_called": this.state.last_name_called,
        "email_called": this.state.email_called,
        "phone_called": this.state.phone_called,

        "first_name_used": firstNameUsed,
        "last_name_used": secondNameUsed,
        "email_used": emailUsed,
        "phone_used": phoneUsed,

        "first_name_required": firstNameRequired,
        "last_name_required": secondNameRequired,
        "email_required": emailRequired,
        "phone_required": phoneRequired,

        "arriving_photo_required": this.state.arriving_photo_required,
        "leaving_photo_required": this.state.leaving_photo_required,

        "send_email_on_check_in": this.state.send_email_on_check_in,
        "send_email_on_check_out": this.state.send_email_on_check_out,
        "text_on_select": this.state.text_on_select,
        "text_on_check_in": this.state.text_on_check_in,
        "text_on_check_out": this.state.text_on_check_out,

        "information_on_check_in": this.state.information_on_check_in,
        "information_on_check_out": this.state.information_on_check_out,
        "check_in_info_confirmation_needed": this.state.check_in_info_confirmation_needed,
        "check_out_info_confirmation_needed": this.state.check_out_info_confirmation_needed,

        "text_emailed_on_check_in": this.state.text_emailed_on_check_in,
        "link_emailed_on_check_in": {
          "url": this.state.link_url_emailed_on_check_in,
          "text": this.state.link_text_emailed_on_check_in
        },
        "text_emailed_on_check_out": this.state.text_emailed_on_check_out,
        "link_emailed_on_check_out": {
          "url": this.state.link_url_emailed_on_check_out,
          "text": this.state.link_text_emailed_on_check_out
        },
        "auto_checkout_time": this.state.auto_checkout_time,
        "auto_checkout_interval": this.state.auto_checkout_interval,
        "hidden": this.state.hidden,
        "covid_pass_check_required": this.state.covid_pass_check_required,
        "covid_pass_alert_email": this.state.covid_pass_alert_email,
        "ask_visiting_people": this.state.ask_visiting_people,
        "visitable_types_of_people": this.state.visitable_types_of_people,
        "disable_auto_create": this.state.disable_auto_create,
        "arrival_email": this.state.arrival_email,

        "feedback_type_ids": this.state.feedback_type_ids,
        "device_ids": this.state.device_ids,
        "custom_attribute_ids": this.state.custom_attribute_ids,
        "auto_tag_ids": this.state.auto_tag_ids
      }
    })

    const requestOptions = {
      method: 'PUT',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    let errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/profiles/${id}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        this.setState({ errors: "" })
        sessionStorage.setItem("updateSuccess", "Profile updated successfully!");
        this.props.closeEditPanel()
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  renderPhotoWarnings() {
    if (this.state.arriving_photo_required !== "never" || this.state.leaving_photo_required !== "never") {
      let scenario = "";

      if (this.state.arriving_photo_required !== "never") {
        scenario = "arrive"
      }

      if (this.state.leaving_photo_required !== "never") {
        if (scenario) {
          scenario += " and "
        }

        scenario += "leave"
      }

      return (
        <NoticeBox type={"warning"}>
          <div>Photos will be taken when people {scenario}. It is your responsibility to let these people know that they may be photographed. Do not select this option if it is not within your rights to do so, or have gained consent from everyone who may be photographed.</div>
        </NoticeBox>
      )
    }
  }

  renderVisitableTypesOfPeople(visitable_types_of_people, ask_visiting_people) {
    if (ask_visiting_people !== "never") {
      let type_of_people = TYPE_OF_PEOPLE
      if (this.state.hideResidents) {
        type_of_people = type_of_people.filter(type_of_person => type_of_person !== "resident")
      }

      return (
        <div className="row">
          <HelpText page={'profile'} section={'visitable_types_of_people'} />

          <label className="column">Visitable Types of People:</label>{ this.renderErrors('visitable_types_of_people') }
          <table className="checkbox-table">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Use</th>
              </tr>
              {type_of_people.map((type_of_person) => (
                <tr key={type_of_person}>
                  <td>{capitalize(type_of_person)}</td>
                  <td className="center-cell">
                    <input className="column" type="checkbox" data-name={type_of_person} name={type_of_person} checked={visitable_types_of_people.includes(type_of_person)} onChange={(event) => this.handleMultiCheckbox(event, "visitable_types_of_people", false)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    }
  }


  renderErrors(field) {
    if (this.state.errors[field]) {

      let capitalCaseField = field.charAt(0).toUpperCase() + field.slice(1)

      return (
        <div className="error">{`${capitalCaseField} ${this.state.errors[field]}`}</div>
      )
    }
  }

  renderSaveButton() {
    if (adminUser()) {
      return <input type="submit" value="Save" />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  render() {

    const {
      name, display_name, type_of_person, first_name_called, last_name_called,
      email_called, phone_called, first_name_radio, last_name_radio, email_radio, phone_radio, arriving_photo_required, leaving_photo_required,
      send_email_on_check_in, send_email_on_check_out, text_on_select, text_on_check_in, text_on_check_out,
      information_on_check_in, information_on_check_out, check_in_info_confirmation_needed, check_out_info_confirmation_needed,
      text_emailed_on_check_in, link_text_emailed_on_check_in, link_url_emailed_on_check_in,
      text_emailed_on_check_out, link_text_emailed_on_check_out, link_url_emailed_on_check_out,
      auto_checkout_time, auto_checkout_interval, hidden,
      covid_pass_check_required, covid_pass_alert_email, ask_visiting_people, visitable_types_of_people, disable_auto_create, arrival_email,
      feedback_type_ids, feedbackTypes,
      device_ids, devices,
      custom_attribute_ids, customAttributes,
      auto_tag_ids, tags,

      loaded, devicesLoaded, feedbackTypesLoaded, customAttributesLoaded, tagsLoaded,

      hideResidents,

      unauthorized, errors, error
    } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false || feedbackTypesLoaded === false || devicesLoaded === false || customAttributesLoaded === false || tagsLoaded === false) {
      return <p>Loading ...</p>;
    }

    const baseLicense = localStorage.license === "base"

    const selectOptions = tags.map(tag => ({ value: tag.id, label: tag.name }))
    const selectedTags = selectOptions.filter(option => auto_tag_ids.includes(option.value))

    return (
      <div>
        <SetTitle title={`Edit Profile | ${name} | Profiles`} />

        <form className="settings big-settings segment-settings" onSubmit={this.handleSubmit}>
          <div className="segment">
            <div className="row">
              <HelpText page={'profile'} section={'name'} />

              <label className="column">Profile Name:</label>{ this.renderErrors('name') }
              <input className="column" type="text" name="name" value={name} onChange={this.handleChange} />
            </div>

            <div className="row">
              <HelpText page={'profile'} section={'display_name'} />

              <label className="column">Display Name:</label>{ this.renderErrors('display_name') }
              <input className="column" type="text" name="display_name" value={display_name} onChange={this.handleChange} />
            </div>

            <div className="row">
              <HelpText page={'profile'} section={hideResidents ? 'type_of_person_without_residents' : 'type_of_person'} />

              <label className="column">Categorise as:</label>{ this.renderErrors('type_of_person') }
              <TypeOfPersonSelect value={type_of_person} onChange={this.handleSearchChange} />
            </div>
          </div>

          <div className="segment">
            <HelpText page={'profile'} section={'called_and_required'} />

            { this.renderErrors('required') }
            <table>
              <tbody>
                <tr>
                  <th></th>
                  <th>Called</th>
                  <th>Required</th>
                  <th>Optional</th>
                  <th>Not Used</th>
                </tr>

                <tr>
                  <td>
                    <label className="column">First Name Called:</label>
                    { this.renderErrors('first_name_called') }
                    { this.renderErrors('first_name_used') }
                    { this.renderErrors('first_name_required') }
                  </td>
                  <td>
                    <input className="column" type="text" name="first_name_called" value={first_name_called} onChange={this.handleChange} />
                  </td>
                  <td>
                    <input className="column" type="radio" name="first_name_radio" value="required" checked={first_name_radio === "required"} onChange={this.handleChange} />
                  </td>
                  <td>
                    <input className="column" type="radio" name="first_name_radio" value="optional" checked={first_name_radio === "optional"} onChange={this.handleChange} />
                  </td>
                  <td>
                    <input className="column" type="radio" name="first_name_radio" value="" checked={first_name_radio === ""} onChange={this.handleChange} />
                  </td>
                </tr>

                <tr>
                  <td>
                    <label className="column">Second Name Called:</label>
                    { this.renderErrors('last_name_called') }
                    { this.renderErrors('last_name_used') }
                    { this.renderErrors('last_name_required') }
                  </td>
                  <td>
                    <input className="column" type="text" name="last_name_called" value={last_name_called} onChange={this.handleChange} />
                  </td>
                  <td>
                    <input className="column" type="radio" name="last_name_radio" value="required" checked={last_name_radio === "required"} onChange={this.handleChange} />
                  </td>
                  <td>
                    <input className="column" type="radio" name="last_name_radio" value="optional" checked={last_name_radio === "optional"} onChange={this.handleChange} />
                  </td>
                  <td>
                    <input className="column" type="radio" name="last_name_radio" value="" checked={last_name_radio === ""} onChange={this.handleChange} />
                  </td>
                </tr>

                <tr>
                  <td>
                    <label className="column">Email Called:</label>
                    { this.renderErrors('email_called') }
                    { this.renderErrors('email_used') }
                    { this.renderErrors('email_required') }
                  </td>
                  <td>
                    <input className="column" type="text" name="email_called" value={email_called} onChange={this.handleChange} />
                  </td>
                  <td>
                    <input className="column" type="radio" name="email_radio" value="required" checked={email_radio === "required"} onChange={this.handleChange} />
                  </td>
                  <td>
                    <input className="column" type="radio" name="email_radio" value="optional" checked={email_radio === "optional"} onChange={this.handleChange} />
                  </td>
                  <td>
                    <input className="column" type="radio" name="email_radio" value="" checked={email_radio === ""} onChange={this.handleChange} />
                  </td>
                </tr>

                <tr>
                  <td>
                    <label className="column">Phone Called:</label>
                    { this.renderErrors('phone_called') }
                    { this.renderErrors('phone_used') }
                    { this.renderErrors('phone_required') }
                  </td>
                  <td>
                    <input className="column" type="text" name="phone_called" value={phone_called} onChange={this.handleChange} />
                  </td>
                  <td>
                    <input className="column" type="radio" name="phone_radio" value="required" checked={phone_radio === "required"} onChange={this.handleChange} />
                  </td>
                  <td>
                    <input className="column" type="radio" name="phone_radio" value="optional" checked={phone_radio === "optional"} onChange={this.handleChange} />
                  </td>
                  <td>
                    <input className="column" type="radio" name="phone_radio" value="" checked={phone_radio === ""} onChange={this.handleChange} />
                  </td>
                </tr>

              </tbody>
            </table>
          </div>

          {baseLicense && (
            <div className="segment">
              <div className="row">
                <HelpText page={'profile'} section={'arriving_photo_required'} />

                <label className="column">Take photos on Arrival?</label>{ this.renderErrors('arriving_photo_required') }

                <div className="radial-three-options">
                  <div className="radial-option">
                    <input className="column" type="radio" name="arriving_photo_required" id="always" value="always" checked={arriving_photo_required === "always"} onChange={this.handleChange} />
                    <label htmlFor="always">Always</label>
                  </div>

                  <div className="radial-option">
                    <input className="column" type="radio" name="arriving_photo_required" id="sometimes" value="sometimes" checked={arriving_photo_required === "sometimes"} onChange={this.handleChange} />
                    <label htmlFor="sometimes">Sometimes</label>
                  </div>

                  <div className="radial-option">
                    <input className="column" type="radio" name="arriving_photo_required" id="never" value="never" checked={arriving_photo_required === "never"} onChange={this.handleChange} />
                    <label htmlFor="never">Never</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <HelpText page={'profile'} section={'leaving_photo_required'} />

                <label className="column">Take photos on Leaving?</label>{ this.renderErrors('leaving_photo_required') }

                <div className="radial-three-options">
                  <div className="radial-option">
                    <input className="column" type="radio" name="leaving_photo_required" id="always" value="always" checked={leaving_photo_required === "always"} onChange={this.handleChange} />
                    <label htmlFor="always">Always</label>
                  </div>

                  <div className="radial-option">
                    <input className="column" type="radio" name="leaving_photo_required" id="sometimes" value="sometimes" checked={leaving_photo_required === "sometimes"} onChange={this.handleChange} />
                    <label htmlFor="sometimes">Sometimes</label>
                  </div>

                  <div className="radial-option">
                    <input className="column" type="radio" name="leaving_photo_required" id="never" value="never" checked={leaving_photo_required === "never"} onChange={this.handleChange} />
                    <label htmlFor="never">Never</label>
                  </div>
                </div>
              </div>

              {this.renderPhotoWarnings()}
            </div>
          )}

          {baseLicense && (
            <div className="segment">
              <div className="row">
                <HelpText page={'profile'} section={'custom_attribute'} />

                <label className="column">Custom Attributes:</label>{ this.renderErrors('custom_attribute') }
                <div className="scrollable-table">
                  <table className="checkbox-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Use</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customAttributes.map((customAttribute) => (
                        <tr key={customAttribute.id}>
                          <td>
                            <Link to={`/configuration/custom-attributes/${customAttribute.id}`}>{customAttribute.name}</Link>
                          </td>
                          <td className="center-cell">
                            <input className="column" type="checkbox" data-name={customAttribute.name} name={customAttribute.id} checked={custom_attribute_ids.includes(customAttribute.id)} onChange={(event) => this.handleMultiCheckbox(event, "custom_attribute_ids")} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          <div className="segment">
            <div className="row">
              <HelpText page={'profile'} section={'auto_tags'} />

              <label className="column">Automatically Tag People as:</label>
              <Select onChange={this.handleMultiSelectChange} options={selectOptions} value={selectedTags} isMulti={true} unstyled={true} className="react-select" classNamePrefix="react-select" placeholder={"Nothing"} />
            </div>
          </div>

          <div className="segment">
            <div className="row">
              <HelpText page={'profile'} section={'text_on_select'} />

              <label className="column">Text on Select:</label>{ this.renderErrors('text_on_select') }
              <input className="column" type="text" name="text_on_select" value={text_on_select} onChange={this.handleChange} />
            </div>

            <div className="row">
              <HelpText page={'profile'} section={'text_on_check_in'} />

              <label className="column">Text on Arrival:</label>{ this.renderErrors('text_on_check_in') }
              <input className="column" type="text" name="text_on_check_in" value={text_on_check_in} onChange={this.handleChange} />
            </div>

            <div className="row">
              <HelpText page={'profile'} section={'text_on_check_out'} />

              <label className="column">Text on Leaving:</label>{ this.renderErrors('text_on_check_out') }
              <input className="column" type="text" name="text_on_check_out" value={text_on_check_out} onChange={this.handleChange} />
            </div>
          </div>

          <div className="segment">
            <div className="textbox-and-checkbox">
              <div className="textbox">
                <HelpText page={'profile'} section={'information_on_check_in'} />

                <label className="column">Information on Arriving:</label>{ this.renderErrors('information_on_check_in') }
                <textarea aria-required="true" name="information_on_check_in" value={information_on_check_in} onChange={this.handleChange} />
              </div>

              <div className="checkbox">
                <input className="column" type="checkbox" name="check_in_info_confirmation_needed" id="check_in_info_confirmation_needed_checkbox" checked={check_in_info_confirmation_needed} onChange={this.handleCheckbox} />
                <label className="column checkbox-label" htmlFor="check_in_info_confirmation_needed_checkbox">Confirmation needed?</label>{ this.renderErrors('check_in_info_confirmation_needed') }
              </div>
            </div>

            <div className="textbox-and-checkbox">
              <div className="textbox">
                <HelpText page={'profile'} section={'information_on_check_out'} />

                <label className="column">Information on Leaving:</label>{ this.renderErrors('information_on_check_out') }
                <textarea aria-required="true" name="information_on_check_out" value={information_on_check_out} onChange={this.handleChange} />
              </div>

              <div className="checkbox">
                <input className="column" type="checkbox" name="check_out_info_confirmation_needed" id="check_out_info_confirmation_needed_checkbox" checked={check_out_info_confirmation_needed} onChange={this.handleCheckbox} />
              <label className="column checkbox-label" htmlFor="check_out_info_confirmation_needed_checkbox">Confirmation needed?</label>{ this.renderErrors('check_out_info_confirmation_needed') }
              </div>
            </div>
          </div>

          {baseLicense && (
            <>
              <div className="segment">
                <div className="row">
                  <HelpText page={'profile'} section={'arrival_email'} />

                  <label className="column">Send alerts to this email for all Arrivals:</label>{ this.renderErrors('arrival_email') }
                  <input className="column" type="text" name="arrival_email" value={arrival_email} onChange={this.handleChange} />
                </div>
              </div>

              <div className="segment">
                <div className="row">
                  <HelpText page={'profile'} section={'text_emailed_on_check_in'} />

                  <input className="column" type="checkbox" name="send_email_on_check_in" id="send_email_on_check_in_checkbox" checked={send_email_on_check_in} onChange={this.handleCheckbox} />
                  <label className="column checkbox-label" htmlFor="send_email_on_check_in_checkbox">Send Email to Person on Arrival?</label>{ this.renderErrors('send_email_on_check_in') }
                </div>

                <div className="row">
                  <label className="column">Text Emailed to Person on Arrival:</label>{ this.renderErrors('text_emailed_on_check_in') }
                  <textarea aria-required="true" name="text_emailed_on_check_in" value={text_emailed_on_check_in} disabled={send_email_on_check_in === false} onChange={this.handleChange} />
                </div>

                <div className="row">
                  <HelpText page={'profile'} section={'link_text'} />

                  <label className="column">Email Link Text:</label>
                  <input className="column small-bottom-margin" type="input" name={"link_text_emailed_on_check_in"} value={link_text_emailed_on_check_in} disabled={send_email_on_check_in === false} onChange={this.handleChange} />

                  <HelpText page={'profile'} section={'link_url'} />

                  <label className="column">Email Link URL:</label>
                  <input className="column small-bottom-margin" type="input" name={"link_url_emailed_on_check_in"} value={link_url_emailed_on_check_in} disabled={send_email_on_check_in === false} onChange={this.handleChange} />

                  <HelpText page={'profile'} section={'link_preview'} />

                  <div className="row bottom-margin">
                    <label className="column">Email Link Preview:</label>
                    <a className="link-preview" target="_blank" rel="noopener noreferrer" href={link_url_emailed_on_check_in}>{link_text_emailed_on_check_in}</a>
                  </div>
                </div>

                <div className="row">
                  <HelpText page={'profile'} section={'text_emailed_on_check_out'} />

                  <input className="column" type="checkbox" name="send_email_on_check_out" id="send_email_on_check_out_checkbox" checked={send_email_on_check_out} onChange={this.handleCheckbox} />
                  <label className="column checkbox-label" htmlFor="send_email_on_check_out_checkbox">Send Email to Person on Leaving?</label>{ this.renderErrors('send_email_on_check_out') }
                </div>

                <div className="row">
                  <label className="column">Text Emailed to Person on Leaving:</label>{ this.renderErrors('text_emailed_on_check_out') }
                  <textarea aria-required="true" name="text_emailed_on_check_out" value={text_emailed_on_check_out} disabled={send_email_on_check_out === false} onChange={this.handleChange} />
                </div>

                <div className="row">
                  <HelpText page={'profile'} section={'link_text'} />

                  <label className="column">Email Link Text:</label>
                  <input className="column small-bottom-margin" type="input" name={"link_text_emailed_on_check_out"} value={link_text_emailed_on_check_out} disabled={send_email_on_check_out === false} onChange={this.handleChange} />

                  <HelpText page={'profile'} section={'link_url'} />

                  <label className="column">Email Link URL:</label>
                  <input className="column small-bottom-margin" type="input" name={"link_url_emailed_on_check_out"} value={link_url_emailed_on_check_out} disabled={send_email_on_check_out === false} onChange={this.handleChange} />

                  <HelpText page={'profile'} section={'link_preview'} />

                  <div className="row bottom-margin">
                    <label className="column">Email Link Preview:</label>
                    <a className="link-preview" target="_blank" rel="noopener noreferrer" href={link_url_emailed_on_check_out}>{link_text_emailed_on_check_out}</a>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="segment">
            <div className="row">
              <HelpText page={'profile'} section={'feedback_type'} />

              <label className="column">Feedback Types:</label>{ this.renderErrors('feedback_type') }
              <div className="scrollable-table">
                <table className="checkbox-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Use</th>
                    </tr>
                  </thead>
                  <tbody>
                    {feedbackTypes.map((feedbackType) => (
                      <tr key={feedbackType.id}>
                        <td>
                          <Link to={`/configuration/feedback/${feedbackType.id}`}>{feedbackType.name}</Link>
                        </td>
                        <td className="center-cell">
                          <input className="column" type="checkbox" data-name={feedbackType.name} name={feedbackType.id} checked={feedback_type_ids.includes(feedbackType.id)} onChange={(event) => this.handleMultiCheckbox(event, "feedback_type_ids")} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {baseLicense && (
            <div className="segment">
              <div className="row">
                <HelpText page={'profile'} section={'covid_pass_check_required_checkbox'} />

                <input className="column" type="checkbox" name="covid_pass_check_required" id="covid_pass_check_required_checkbox" checked={covid_pass_check_required} onChange={this.handleCheckbox} />
                <label className="column checkbox-label" htmlFor="covid_pass_check_required_checkbox">Covid vaccination check required?</label>{ this.renderErrors('covid_pass_check_required') }
              </div>

              <div className="row">
                <HelpText page={'profile'} section={'covid_pass_check_required_email'} />

                <label className="column">Email alert for failed Covid vaccination check:</label>{ this.renderErrors('covid_pass_alert_email') }
                <input className="column" type="text" name="covid_pass_alert_email" value={covid_pass_alert_email} disabled={covid_pass_check_required === false} onChange={this.handleChange} />
              </div>
            </div>
          )}

          <div className="segment">
            {baseLicense && (
              <>
                <div className="row">
                  <HelpText page={'profile'} section={'ask_visiting_people'} />

                  <label className="column">Ask about Visiting People:</label>{ this.renderErrors('ask_visiting_people') }

                  <div className="radial-three-options">
                    <div className="radial-option">
                      <input className="column" type="radio" name="ask_visiting_people" id="never" value="never" checked={ask_visiting_people === "never"} onChange={this.handleChange} />
                      <label htmlFor="never">Never</label>
                    </div>

                    <div className="radial-option">
                      <input className="column" type="radio" name="ask_visiting_people" id="optional" value="optional" checked={ask_visiting_people === "optional"} onChange={this.handleChange} />
                      <label htmlFor="optional">Optional</label>
                    </div>

                    <div className="radial-option">
                      <input className="column" type="radio" name="ask_visiting_people" id="enforce" value="enforce" checked={ask_visiting_people === "enforce"} onChange={this.handleChange} />
                      <label htmlFor="enforce">Enforce</label>
                    </div>
                  </div>
                </div>

                {this.renderVisitableTypesOfPeople(visitable_types_of_people, ask_visiting_people)}
              </>
            )}

            <div className="row">
              <HelpText page={'profile'} section={'disable_auto_create'} />

              <input className="column" type="checkbox" name="disable_auto_create" id="disable_auto_create_checkbox" checked={disable_auto_create} onChange={this.handleCheckbox} />
              <label className="column checkbox-label" htmlFor="disable_auto_create_checkbox">Disable Auto Create of People:</label>{ this.renderErrors('disable_auto_create') }
            </div>
          </div>

          <div className="segment">
            <div className="row">
              <HelpText page={'profile'} section={hideResidents ? 'auto_checkout_time_without_residents' : 'auto_checkout_time'} />

              <label className="column">Auto Checkout after Time:</label>{ this.renderErrors('auto_checkout_time') }
              <input className="column" type="time" name="auto_checkout_time" value={auto_checkout_time} onChange={this.handleChange} />
            </div>

            <div className="row">
              <HelpText page={'profile'} section={hideResidents ? 'auto_checkout_interval_without_residents' : 'auto_checkout_interval'} />

              <label className="column">Auto Checkout after number of hours:</label>{ this.renderErrors('auto_checkout_interval') }
              <input className="column" type="text" name="auto_checkout_interval" value={auto_checkout_interval} onChange={this.handleChange} />
            </div>
          </div>

          {baseLicense && (
            <div className="segment">
              <div className="row">
                <HelpText page={'profile'} section={'hidden'} />

                <input className="column" type="checkbox" name="hidden" id="hidden_checkbox" checked={hidden} onChange={this.handleCheckbox} />
                <label className="column checkbox-label" htmlFor="hidden_checkbox">Hidden?</label>{ this.renderErrors('hidden') }
              </div>
            </div>
          )}

          <div className="segment">
            <div className="row">
              <HelpText page={'profile'} section={'devices'} />

              <label className="column">Devices:</label>{ this.renderErrors('devices') }
              <div className="scrollable-table">
                <table className="checkbox-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Use</th>
                    </tr>
                  </thead>
                  <tbody>
                    {devices.map((device) => (
                      <tr key={device.id}>
                        <td>
                          <Link to={`/configuration/devices/${device.id}`}>{device.name}</Link>
                        </td>
                        <td className="center-cell">
                          <input className="column" type="checkbox" data-name={device.name} name={device.id} checked={device_ids.includes(device.id)} onChange={(event) => this.handleMultiCheckbox(event, "device_ids")} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="segment">
            { this.renderSaveButton() }
            { renderErrorWarning(errors) }
          </div>
        </form>

        <Audits klass="Profile" id={this.props.id} />
      </div>
    );
  }

  componentDidMount() {
    const id = this.props.id;

    let headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    const requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/profiles/${id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        let firstNameRadio = this.convertToRadio(data.first_name_used, data.first_name_required)
        let secondNameRadio = this.convertToRadio(data.last_name_used, data.last_name_required)
        let emailRadio = this.convertToRadio(data.email_used, data.email_required)
        let phoneRadio = this.convertToRadio(data.phone_used, data.phone_required)

        this.setState({
          name: data.name,
          display_name: data.display_name,
          type_of_person: data.type_of_person,
          first_name_called: data.first_name_called,
          last_name_called: data.last_name_called,
          email_called: data.email_called,
          phone_called: data.phone_called,

          first_name_radio: firstNameRadio,
          last_name_radio: secondNameRadio,
          email_radio: emailRadio,
          phone_radio: phoneRadio,

          arriving_photo_required: data.arriving_photo_required,
          leaving_photo_required: data.leaving_photo_required,

          send_email_on_check_in: data.send_email_on_check_in,
          send_email_on_check_out: data.send_email_on_check_out,
          text_on_select: data.text_on_select || "",
          text_on_check_in: data.text_on_check_in,
          text_on_check_out: data.text_on_check_out,

          information_on_check_in: data.information_on_check_in || "",
          information_on_check_out: data.information_on_check_out || "",
          check_in_info_confirmation_needed: data.check_in_info_confirmation_needed,
          check_out_info_confirmation_needed: data.check_out_info_confirmation_needed,

          text_emailed_on_check_in: data.text_emailed_on_check_in || "",
          link_url_emailed_on_check_in: data.link_url_emailed_on_check_in || "",
          link_text_emailed_on_check_in: data.link_text_emailed_on_check_in || "",

          text_emailed_on_check_out: data.text_emailed_on_check_out || "",
          link_url_emailed_on_check_out: data.link_url_emailed_on_check_out || "",
          link_text_emailed_on_check_out: data.link_text_emailed_on_check_out || "",

          auto_checkout_time: data.auto_checkout_time,
          auto_checkout_interval: data.auto_checkout_interval,
          hidden: data.hidden,
          covid_pass_check_required: data.covid_pass_check_required,
          covid_pass_alert_email: data.covid_pass_alert_email,
          ask_visiting_people: data.ask_visiting_people,
          visitable_types_of_people: data.visitable_types_of_people,
          disable_auto_create: data.disable_auto_create,
          arrival_email: data.arrival_email,

          device_ids: data.device_ids,
          feedback_type_ids: data.feedback_type_ids,
          custom_attribute_ids: data.custom_attribute_ids,
          auto_tag_ids: data.auto_tag_ids,

          loaded: true
        })
      })
      .catch(error => this.setState({ error, loaded: true }))

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/feedback_types/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ feedbackTypes: data, feedbackTypesLoaded: true })
      })
      .catch(error => this.setState({ error, feedbackTypesLoaded: true }))

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/devices/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ devices: data, devicesLoaded: true })
      })
      .catch(error => this.setState({ error, devicesLoaded: true }))

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/custom_attributes/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ customAttributes: data, customAttributesLoaded: true })
      })
      .catch(error => this.setState({ error, customAttributesLoaded: true }))

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/tags/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ tags: data, tagsLoaded: true })
      })
      .catch(error => this.setState({ error, tagsLoaded: true }))
  }
}

export default UpdateProfile;
