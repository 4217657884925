import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';
import TypeOfPersonSelect from '../../components/shared/TypeOfPersonSelect';
import HelpText from '../../components/help/HelpText';

import { adminUser, renderErrorWarning } from '../../utilities/Forms.js'
import LoadingSpinner from '../../components/shared/LoadingSpinner';

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class CreateImport extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleHeadersChange = this.handleHeadersChange.bind(this);
    this.handleAddHeadersRow = this.handleAddHeadersRow.bind(this);
    this.handleRemoveHeadersRow = this.handleRemoveHeadersRow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleClickSavePreview = this.handleClickSavePreview.bind(this);
  }

  state = {
    name: "",
    provider: "",
    type_of_person: "",
    api_key: "",
    home_id: "",
    headers: ":",
    inactive: false,
    unique_id_mapping: "",
    first_name_mapping: "",
    last_name_mapping: "",
    alternate_name_mapping: "",
    email_mapping: "",
    phone_mapping: "",
    related_to_mapping: "",
    type_of_related_to_mapping: "",
    filter_mapping: "",
    filter_value: "",
    export_events_to_mcm: false,
    export_resident_events_to_mcm: false,
    location_id: "",
    preview: false,

    locations: [],
    locationsLoaded: false,

    type_of_organisation: localStorage.getItem("type_of_organisation") || "Social Care",

    updated: "",
    updating: false,
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    if (name === "provider" && value === "mcm" && !["resident", "staff", ""].includes(this.state.type_of_person)) {
      this.setState({type_of_person: ""})
    }

    this.setState({[name]: value});
  }

  handleCheckbox(event) {
    let name = event.target.name;
    let value = event.target.checked;

    this.setState({[name]: value});
  }

  handleHeadersChange(event, skipIndex) {
    let headers = ""

    document.querySelectorAll('#header-box div').forEach((row) => {
      if (row.dataset.key === skipIndex) {
        return
      }

      if (headers.length > 0) {
        headers += "\n"
      }

      const key = row.querySelector('input[name="key"]').value.trim()
      const value = row.querySelector('input[name="value"]').value.trim()

      headers += `${key}: ${value}`
    })

    this.setState({headers: headers});
  }

  handleAddHeadersRow(event) {
    let headers = this.state.headers
    headers += "\n:"

    this.setState({headers: headers});

    event.preventDefault();
  }

  handleRemoveHeadersRow(event) {
    let skipIndex = event.currentTarget.parentElement.dataset.key;

    this.handleHeadersChange(event, skipIndex)

    event.preventDefault();
  }

  handleSubmit(event, preview) {
    this.setState({
      updated: "",
      updating: true
    })

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    let api_key = this.state.api_key

    if (this.state.provider === "cool_care") {
      // Store api_key and home_id as serialised json
      api_key = JSON.stringify({ key: this.state.api_key, home_id: this.state.home_id })
    }

    var json = JSON.stringify({
      "import": {
        "name": this.state.name,
        "provider": this.state.provider,
        "type_of_person": this.state.type_of_person,
        "api_key": api_key,
        "headers": this.state.headers,
        "inactive": this.state.inactive,
        "unique_id_mapping": this.state.unique_id_mapping,
        "first_name_mapping": this.state.first_name_mapping,
        "last_name_mapping": this.state.last_name_mapping,
        "alternate_name_mapping": this.state.alternate_name_mapping,
        "email_mapping": this.state.email_mapping,
        "phone_mapping": this.state.phone_mapping,
        "related_to_mapping": this.state.related_to_mapping,
        "type_of_related_to_mapping": this.state.type_of_related_to_mapping,
        "filter_mapping": this.state.filter_mapping,
        "filter_value": this.state.filter_value,
        "export_events_to_mcm": this.state.export_events_to_mcm,
        "export_resident_events_to_mcm": this.state.export_resident_events_to_mcm,
        "location_id": this.state.location_id,
        "preview": preview,
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/imports/`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      } else {
        this.setState({
          id: data.id,
          updated: true,
          updating: false,
          errors: ""
        })
      }
    })
    .catch(error => this.setState({ updating: false, error, loaded: true }))

    event.preventDefault();
  }

  handleClickSave(event){
    this.setState({ preview: false });
    this.handleSubmit(event, false);;
  }

  handleClickSavePreview(event) {
    this.setState({ preview: true });
    this.handleSubmit(event, true);;
  }

  renderErrors(field) {
    if (this.state.errors[field]) {
      let capitalCaseField = field.charAt(0).toUpperCase() + field.slice(1)

      return (
        <div className="error">{`${capitalCaseField} ${this.state.errors[field]}`}</div>
      )
    }
  }

  renderUpdating(preview, updating) {
    if (updating && preview) {
      return (
        <div>
          Saving and preparing preview of data...
        </div>
      );
    }
    else if (updating) {
      return (
        <div>
          Saving...
        </div>
      );
    }
  }

  renderHeadersBoxes(headers) {
    let fields = []

    if (headers === null) {
      fields.push(
        <div className="key-value-pairs" key="0" data-key="0">
          <input type="text" name="key" value="" onChange={this.handleHeadersChange} />
          <input type="text" name="value" value="" onChange={this.handleHeadersChange} />
          <FontAwesomeIcon onClick={this.handleRemoveHeadersRow} icon={faTimes} />
        </div>
      )
    }
    else {
      const rows = headers.split("\n")

      rows.forEach((row, index) => {
        const key = row.split(":")[0].trim()
        const value = row.split(":")[1].trim()

        fields.push(
          <div className="key-value-pairs" key={index} data-key={index}>
            <input type="text" name="key" value={key} onChange={this.handleHeadersChange} />
            <input type="text" name="value" value={value} onChange={this.handleHeadersChange} />
            <FontAwesomeIcon onClick={this.handleRemoveHeadersRow} icon={faTimes} />
          </div>
        )
      })
    }

    return (
      <div id="header-box">
        {fields}
      </div>
    )
  }

  providerSpecificFields() {
    const {
      api_key, home_id, headers, unique_id_mapping, first_name_mapping, last_name_mapping, alternate_name_mapping,
      email_mapping, phone_mapping, related_to_mapping, type_of_related_to_mapping, filter_mapping, filter_value,
    } = this.state;

    if (this.state.provider === "generic") {
      return (
        <div>
          <div className="row">
            <HelpText page={'import'} section={'apiUrl'} />
            <label className="column">Full API URL:</label>{ this.renderErrors('api_key') }
            <input className="column" type="text" name="api_key" value={api_key} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'import'} section={'headers'} />
            <label className="column">Headers:</label>{ this.renderErrors('headers') }
          </div>
          <div className="row">
            <div className="key-value-pairs">
              <label>Key:</label>
              <label>Value:</label>
            </div>
            {this.renderHeadersBoxes(headers)}
            <button className="button right-button" onClick={this.handleAddHeadersRow}>Add row</button>
          </div>

          <div className="row">
            <HelpText page={'import'} section={'idMapping'} />
            <label className="column">Unique ID Mapping:</label>{ this.renderErrors('unique_id_mapping') }
            <input className="column" type="text" name="unique_id_mapping" value={unique_id_mapping} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'import'} section={'firstNameMapping'} />
            <label className="column">First Name Mapping:</label>{ this.renderErrors('first_name_mapping') }
            <input className="column" type="text" name="first_name_mapping" value={first_name_mapping} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'import'} section={'lastNameMapping'} />
            <label className="column">Last Name Mapping:</label>{ this.renderErrors('last_name_mapping') }
            <input className="column" type="text" name="last_name_mapping" value={last_name_mapping} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'import'} section={'alternateNameMapping'} />
            <label className="column">Alternate Name Mapping:</label>{ this.renderErrors('alternate_name_mapping') }
            <input className="column" type="text" name="alternate_name_mapping" value={alternate_name_mapping} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'import'} section={'emailMapping'} />
            <label className="column">Email Mapping:</label>{ this.renderErrors('email_mapping') }
            <input className="column" type="text" name="email_mapping" value={email_mapping} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'import'} section={'phoneMapping'} />
            <label className="column">Phone Mapping:</label>{ this.renderErrors('phone_mapping') }
            <input className="column" type="text" name="phone_mapping" value={phone_mapping} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'import'} section={'relatesMapping'} />
            <label className="column">Related To Mapping:</label>{ this.renderErrors('related_to_mapping') }
            <input className="column" type="text" name="related_to_mapping" value={related_to_mapping} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'import'} section={'relatesTypeMapping'} />
            <label className="column">Type of Related To Mapping:</label>{ this.renderErrors('type_of_related_to_mapping') }
            <input className="column" type="text" name="type_of_related_to_mapping" value={type_of_related_to_mapping} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'import'} section={'filterMapping'} />
            <label className="column">Filter Mapping:</label>{ this.renderErrors('filter_mapping') }
            <input className="column" type="text" name="filter_mapping" value={filter_mapping} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'import'} section={'filterValueMapping'} />
            <label className="column">Filter Value:</label>{ this.renderErrors('filter_value') }
            <input className="column" type="text" name="filter_value" value={filter_value} onChange={this.handleChange} />
          </div>
        </div>
      )
    }
    else if (this.state.provider === "mcm") {
      return (
        <div>
          <div className="row">
            <HelpText page={'import'} section={'mcmKey'} />
            <label className="column">mCare API Key:</label>{ this.renderErrors('api_key') }
            <input className="column" type="text" name="api_key" value={api_key} onChange={this.handleChange} />
          </div>

          { this.exportEventsCheckbox() }
        </div>
      )
    }
    else if (this.state.provider === "cool_care") {
      return (
        <div>
          <div className="row">
            <HelpText page={'import'} section={'coolCareKey'} />
            <label className="column">Cool Care API Key:</label>{ this.renderErrors('api_key') }
            <input className="column" type="text" name="api_key" value={api_key} onChange={this.handleChange} />
          </div>

          <div className="row">
            <HelpText page={'import'} section={'coolCareHomeId'} />
            <label className="column">Cool Care Home ID:</label>{ this.renderErrors('home_id') }
            <input className="column" type="text" name="home_id" value={home_id} onChange={this.handleChange} />
          </div>
        </div>
      )
    }
  }

  exportEventsCheckbox() {
    const { export_events_to_mcm, export_resident_events_to_mcm } = this.state;

    if (this.state.type_of_person === "resident") {
      return (
        <>
          <div className="row">
            <HelpText page={'import'} section={'exportEventsToMcm'} />

            <input className="column" type="checkbox" name="export_events_to_mcm" id="export_events_to_mcm" checked={export_events_to_mcm} onChange={this.handleCheckbox} />
            <label className="column checkbox-label" htmlFor="export_events_to_mcm">Export Visitor Events to mCare?</label>{ this.renderErrors('export_events_to_mcm') }
          </div>

          <div className="row">
            <HelpText page={'import'} section={'exportResidentEventsToMcm'} />

            <input className="column" type="checkbox" name="export_resident_events_to_mcm" id="export_resident_events_to_mcm" checked={export_resident_events_to_mcm} onChange={this.handleCheckbox} />
            <label className="column checkbox-label" htmlFor="export_resident_events_to_mcm">Export Resident Events to mCare?</label>{ this.renderErrors('export_resident_events_to_mcm') }
          </div>
        </>
      )
    }
  }

  renderSaveButton() {
    if (adminUser()) {
      return (
        <div>
          <button className="button" disabled={this.state.updating} onClick={this.handleClickSavePreview}>
            Save & preview data
            <LoadingSpinner condition={this.state.updating && this.state.preview} />
          </button>
          <button className="button" disabled={this.state.updating} onClick={this.handleClickSave}>
            Save as draft
            <LoadingSpinner condition={this.state.updating && this.state.preview === false} />
          </button>
        </div>
      )
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  render() {
    const { name, provider, type_of_person, inactive, location_id, locations, locationsLoaded, id, updated, unauthorized, errors, error, preview, updating, type_of_organisation } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (updated && id) {
      return <Redirect to={`/imports/${id}`}/>
    }

    if (locationsLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (locationsLoaded) {
      const loading = updating ? "loading" : ""

      return (
        <div className={loading}>
          <SetTitle title={`New Import | ${name} | Imports`} />

          <form className="settings big-settings">
            <div className="row">
              <HelpText page={'import'} section={'name'} />

              <label className="column">Import Name:</label>{ this.renderErrors('name') }
              <input className="column" type="text" name="name" value={name} onChange={this.handleChange} />
            </div>

            <div className="row">
              <HelpText page={'import'} section={'location'} />

              <label className="column">Location:</label>{ this.renderErrors('location') }
              <select name="location_id" onChange={this.handleChange} value={location_id}>
                <option value="">Select</option>
                {locations.map((location) => (
                  <option value={location.id} key={location.id}>{location.name}</option>
                ))}
              </select>
            </div>

            <div className="row">
              <HelpText page={'import'} section={'provider'} />

              <label className="column">Provider:</label>{ this.renderErrors('provider') }
              <select name="provider" onChange={this.handleChange} value={provider}>
                <option value="">Select</option>
                <option value="generic">Generic</option>
                {type_of_organisation === "Social Care" && <option value="mcm">mCare</option>}
                {type_of_organisation === "Social Care" && <option value="cool_care">Cool Care</option>}
              </select>
            </div>

            <div className="row">
              <HelpText page={'import'} section={type_of_organisation === "Retail" ? 'personTypeWithoutResidents' : 'personType'} />

              <label className="column">Type of Person:</label>{ this.renderErrors('type_of_person') }
              <TypeOfPersonSelect value={type_of_person} onChange={this.handleChange} options={provider === "mcm" && ["resident", "staff"]} />
            </div>

            {this.providerSpecificFields()}

            <div className="row">
              <HelpText page={'import'} section={'inactive'} />

              <input className="column" type="checkbox" name="inactive" id="inactive" checked={inactive} onChange={this.handleCheckbox} />
              <label className="column checkbox-label" htmlFor="inactive">Inactive?</label>{ this.renderErrors('inactive') }
            </div>

            { this.renderSaveButton() }
            { this.renderUpdating(preview, updating) }
            <LoadingSpinner condition={updating && preview} />
            { renderErrorWarning(errors) }
          </form>

        </div>
      );
    }
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({
            error: JSON.stringify(response.body),
            unauthorized: true
          })
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ locations: data, locationsLoaded: true })
      })
      .catch(error => this.setState({ error, locationsLoaded: true }))
  }
}

export default CreateImport;
