import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Select from 'react-select'
import { CSVLink } from "react-csv";

import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Page from '../../components/shared/Page';
import TypeOfPersonSelect from '../../components/shared/TypeOfPersonSelect';
import { formatDate, convertToSlug, arrayParamBuilder, STAFF_TYPE_OF_PEOPLE } from '../../utilities/Generic.js'

class WorkedHoursIndex extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
  }

  state = {
    locations: [],
    roles: [],
    tags: [],
    people: [],

    date_from: formatDate(new Date(), 7),
    date_to: formatDate(new Date(), 0),
    location_id: this.props.match.params.id,
    role_id: "",
    type_of_person: "",
    tag_ids: [],

    locationsLoaded: false,
    rolesLoaded: false,
    tagsLoaded: false,
    workedHoursLoaded: false,

    error: null,
    unauthorized: null
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({
      [name]: value,
      workedHoursLoaded: false
    }, () => {
      // A setState callback?! Ensuring all states are set before triggering the next line?!
      this.fetchData()
    });
  }

  handleMultiSelectChange(data) {
    const fakeEvent = {}
    fakeEvent.target = {}
    fakeEvent.target.name = "tag_ids"
    fakeEvent.target.value = data.map(option => option.value);

    this.handleChange(fakeEvent)
  }

  fetchData() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    let params = `date_from=${this.state.date_from}&date_to=${this.state.date_to}&location_id=${this.state.location_id}&role_id=${this.state.role_id}&type_of_person=${this.state.type_of_person}${arrayParamBuilder(null, "tag_ids", this.state.tag_ids)}`

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/staff_fulfilment/worked_hours?${params}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ people: data, workedHoursLoaded: true })
      })
      .catch(error => this.setState({ error, workedHoursLoaded: true }))
  }

  render() {
    const { people, locationsLoaded, rolesLoaded, tagsLoaded, workedHoursLoaded, error, unauthorized } = this.state;

    if (localStorage.license === "entry") {
      return <Redirect to="/upgrade" />
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (locationsLoaded === false || rolesLoaded === false || tagsLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (locationsLoaded && rolesLoaded && tagsLoaded) {
      return (
        <div>
          <Page title="Worked Hours">
            {this.renderFilters()}

            {workedHoursLoaded ? (
              <div className="widgets">
                {people.map((person, index) => (
                  this.renderPersonSection(person, index)
                ))}

                {people.length === 0 && (
                  <p>No data found for those filters.</p>
                )}
              </div>
            ) : (
              <p>Loading ...</p>
            )}
          </Page>
        </div>
      );
    }
  }

  renderDownloadCSV(person, hours) {
    let filename = `${convertToSlug(person.full_name)}-worked-hours-${this.state.date_from}-to-${this.state.date_to}.csv`

    let running_total = 0;
    hours = hours.map(hour => {
      const worked_hours = hour.worked_hours === "N/A" ? 0 : hour.worked_hours
      const excess_hours = hour.excess_hours === "N/A" ? 0 : hour.excess_hours

      running_total += worked_hours + excess_hours;

      return {
        ...hour,
        running_total
      };
    });

    let headers = [
      { label: "Date", key: "date" },
      { label: "Rota Start", key: "rota_start" },
      { label: "Rota End", key: "rota_end" },
      { label: "Rota Breaks", key: "rota_breaks" },
      { label: "Rota Hours", key: "rota_hours" },
      { label: "Actual Start", key: "actual_start" },
      { label: "Actual End", key: "actual_end" },
      { label: "Actual Breaks", key: "actual_breaks" },
      { label: "Actual Hours", key: "actual_hours" },
      { label: "Worked Hours", key: "worked_hours" },
      { label: "Excess Hours", key: "excess_hours" },
      { label: "Running Total", key: "running_total" }
    ];

    return (
      <CSVLink data={hours} headers={headers} filename={filename} className="edit-button download-csv relative-csv">
        <div>
          <FontAwesomeIcon icon={faFileDownload} />
          <h4>Download CSV</h4>
        </div>
      </CSVLink>
    )
  }

  renderPersonSection(person, index) {
    const totalHours = Object.values(person.hours).reduce((total, hour) => {
      let worked_hours = hour.worked_hours === "N/A" ? 0 : hour.worked_hours
      let excess_hours = hour.excess_hours === "N/A" ? 0 : hour.excess_hours

      return total + worked_hours + excess_hours;
    }, 0);

    return (
      <React.Fragment key={index}>
        <h3 className="no-bottom-margin top-padding">{person.full_name}</h3>
        <div className="small-top-padding">Total hours: {parseFloat(totalHours.toFixed(2))}</div>

        <table className="table big-table worked-hours-table bottom-margin" data-testid="table">
          <thead>
            <tr className="tr heading">
              <th></th>
              <th className="th first-child" colSpan="4">Rota</th>
              <th className="th last-child" colSpan="4">Actual</th>
              <th className="" colSpan="2">{this.renderDownloadCSV(person, person.hours)}</th>
            </tr>
            <tr className="tr heading">
              {/* No heading */}
              <th className="th">Date</th>
              {/* Rota */}
              <th className="th">Start</th>
              <th className="th">End</th>
              <th className="th">Breaks</th>
              <th className="th">Hours</th>
              {/* Actual */}
              <th className="th">Start</th>
              <th className="th">End</th>
              <th className="th">Breaks</th>
              <th className="th">Hours</th>
              {/* No heading */}
              <th className="th">Worked</th>
              <th className="th">Excess</th>
            </tr>
          </thead>
          <tbody>
            {person.hours.map((hour, index) => (
              this.renderPersonHours(hour, index)
            ))}
          </tbody>
        </table>
      </React.Fragment>
    )
  }

  renderPersonHours(hour, index) {
    return (
      <tr className="tr" key={index}>
        {/* No heading */}
        <td className="td">{hour.date}</td>
        {/* Rota */}
        <td className="td">{hour.rota_start}</td>
        <td className="td">{hour.rota_end}</td>
        <td className="td">{hour.rota_breaks}</td>
        <td className="td">{hour.rota_hours}</td>
        {/* Actual */}
        <td className={`td${this.redActualStart(hour)}`}>{hour.actual_start}</td>
        <td className="td">{hour.actual_end}</td>
        <td className="td">{hour.actual_breaks}</td>
        <td className="td">{hour.actual_hours}</td>
        {/* No heading */}
        <td className={`td${this.colourWorkedHours(hour)}`}>{hour.worked_hours}</td>
        <td className="td">{hour.excess_hours}</td>
      </tr>
    )
  }

  redActualStart(hour) {
    if (hour.actual_start !== "N/A" && hour.rota_start !== "N/A" && this.timeFrom(hour.actual_start) > this.timeFrom(hour.rota_start) ) {
      return " red-text"
    }

    return ""
  }

  timeFrom(timeString) {
    return new Date(`1970/01/01 ${timeString.slice(0, -2)}`)
  }

  colourWorkedHours(hour) {
    if (hour.rota_hours !== "N/A") {
      if (hour.worked_hours < hour.rota_hours) {
        return " red-text"
      }
      else {
        return " green-text"
      }

    }
    return ""
  }

  renderFilters() {
    const { date_from, date_to, location_id, type_of_person, role_id, tag_ids, locations, roles, tags } = this.state;

    const selectOptions = tags.map(tag => ({ value: tag.id, label: tag.name }))
    const selectedTags = selectOptions.filter(option => tag_ids.includes(option.value))

    return (
      <div className="export-selection">
        <div className="selection-block">
          <label className="column">Date from:</label>
          <input className="column" type="date" name="date_from" value={date_from} onChange={this.handleChange} />
        </div>

        <div className="selection-block">
          <label className="column">Date to:</label>
          <input className="column" type="date" name="date_to" value={date_to} onChange={this.handleChange} />
        </div>

        <div className="selection-block">
          <label className="column">Location:</label>
          <select name="location_id" value={location_id} onChange={this.handleChange}>
            {locations.map((location) => (
              <option value={location.id} key={location.id}>{location.name}</option>
            ))}
          </select>
        </div>

        <div className="selection-block">
          <label className="column">Type:</label>
          <TypeOfPersonSelect defaultText="All" value={type_of_person} onChange={this.handleChange} options={STAFF_TYPE_OF_PEOPLE} />
        </div>

        <div className="selection-block">
          <label className="column">Roles:</label>
          <select name="role_id" value={role_id} onChange={this.handleChange}>
            <option value="">All</option>
            {roles.map((role) => (
              <option value={role.id} key={role.id}>{role.name}</option>
            ))}
          </select>
        </div>

        <div className="selection-block">
          <label className="column">Filter by Tags:</label>
          <Select onChange={this.handleMultiSelectChange} options={selectOptions} value={selectedTags} isMulti={true} unstyled={true} className="react-select" classNamePrefix="react-select" placeholder={"All"} />
        </div>
      </div>
    )
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    // Filter data

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        const filteredLocations = data.filter(location => location.staff_fulfilment)

        localStorage.setItem("staff_fulfilment_access", filteredLocations.length > 0)

        this.setState({ locations: filteredLocations, locationsLoaded: true })
      })
      .catch(error => this.setState({ error, locationsLoaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/roles/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ roles: data, rolesLoaded: true })
      })
      .catch(error => this.setState({ error, rolesLoaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/tags/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ tags: data, tagsLoaded: true })
      })
      .catch(error => this.setState({ error, tagsLoaded: true }))

    // Form data

    this.fetchData()
  }
}

export default WorkedHoursIndex;
