import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

import { request, reportErrorToHoneybadger } from '../../helpers/Requests'
import LoggedOutHeader from '../../components/header/LoggedOutHeader';
import BigLogo from '../../components/header/BigLogo';
import SetTitle from '../../components/shared/SetTitle';

import MicrosoftLoginButton from '../../components/sessions/MicrosoftLoginButton';

class Login extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
    this.handleMicrosoftLogin = this.handleMicrosoftLogin.bind(this);
    this.handleMicrosoftLoginError = this.handleMicrosoftLoginError.bind(this);
  }

  state = {
    emailAddress: sessionStorage.getItem("loginEmail") || "",
    password: "",

    loggingIn: false,
    loggedIn: false,

    errors: "",
    error: "",
    googleError: "",
    microsoftError: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({[name]: value});
  }

  handleSubmit(event) {
    this.setState({
      loggingIn: true,
      errors: "",
      error: "",
      googleError: "",
      microsoftError: ""
    })

    const body = JSON.stringify({
      "email_address": this.state.emailAddress,
      "password": this.state.password,
    })

    request('POST', '/v1/a/sessions', body)
      .then(data => {
        const cookies = new Cookies();
        cookies.set('auth_token', data.cookie, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
        cookies.remove('support')

        this.setLocalStorageItems(data)

        this.setState({
          loggedIn: true,
          loggingIn: false
        })
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        if (error.errors) {
          this.setState({
            errors: JSON.stringify(error),
            loggingIn: false
          })
        }
        else {
          this.setState({
            error: error.message,
            loggingIn: false
          })
        }
      })

    event.preventDefault();
  }

  handleGoogleLogin(response) {
    this.setState({
      loggingIn: true,
      errors: "",
      error: "",
      googleError: "",
      microsoftError: ""
    })

    request('POST', `/v1/a/sessions/google?credentials=${response.credential}`, null)
      .then(data => {
        const cookies = new Cookies();
        cookies.set('auth_token', data.cookie, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
        cookies.remove('support')

        this.setLocalStorageItems(data)

        this.setState({loggedIn: true})
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        this.setState({ googleError: error.error || error.message })
      })
      .then(() => {
        this.setState({ loggingIn: false })
      })
  }

  handleMicrosoftLogin(idToken) {
    this.setState({
      loggingIn: true,
      errors: "",
      error: "",
      googleError: "",
      microsoftError: ""
    })

    request('POST', `/v1/a/sessions/microsoft?credentials=${idToken}`, null)
      .then((data) => {
        this.setState({loggingIn: false})

        if (data.cookie) {
          const cookies = new Cookies();
          cookies.set('auth_token', data.cookie, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
          cookies.remove('support');

          this.setLocalStorageItems(data)

          this.setState({loggedIn: true})
        }
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        this.setState({ microsoftError: error.error || error.message })
      })
      .then(() => {
        this.setState({ loggingIn: false })
      })
  }

  handleMicrosoftLoginError(error) {
    this.setState({
      microsoftError: error.message
    })
  }

  convertResellerId(resellerId) {
    switch(resellerId) {
      case 1:
        return "PCS"
      case 2:
        return "Cool Care"
      default:
        return ""
    }
  }

  setLocalStorageItems(data) {
    localStorage.setItem('access_levels', data.access_levels);
    localStorage.setItem('license', data.license);
    localStorage.setItem('access_levels', data.access_levels);
    localStorage.setItem('staff_fulfilment_access', data.staff_fulfilment_access);
    localStorage.setItem('latestReseller', this.convertResellerId(data.reseller_id));
    localStorage.setItem('type_of_organisation', data.type_of_organisation);
  }

  renderErrors(state) {
    if (state.errors.length > 0) {
      var json = JSON.parse(state.errors)
      var messages = json.errors

      return (
        <div className="errors">
          Sorry, looks like you have some errors:
          <ul>
            {messages.map((message) => (
              <li>{message}</li>
            ))}
          </ul>
        </div>
      )
    }

    if (state.error.length > 0) {
      return (
        <div className="error">{state.error}</div>
      )
    }
  }

  loggingInMessage(state) {
    if (state.loggingIn) {
      return (
        <div>
          Logging in
          <span className="blink1">.</span>
          <span className="blink2">.</span>
          <span className="blink3">.</span>
        </div>
      )
    }
  }

  renderError(error) {
    if (error) {
      return (
        <div className="google-login-error">{error}</div>
      )
    }
  }

  render() {
    sessionStorage.removeItem("loginEmail")

    if (this.state.loggedIn) {
      // after a logged out user who clicked an emergency fire log email has logged in, we should redirect them to the fire log
      if (this.props.location.fireLogLocation) {
        return <Redirect to={`/fire-log?location=${this.props.location.fireLogLocation}`}/>
      }

      if (localStorage.getItem("access_levels") === "emergency") {
        return <Redirect to="/fire-log"/>
      }
      else {
        return <Redirect to="/"/>
      }
    }

    // MSAL configuration
    const msConfig = {
      auth: {
        clientId: "e364d967-a8a3-46cf-8541-26e4de77f4f1"
      }
    };

    const mspca = new PublicClientApplication(msConfig);

    return (
      <div className="login-page">
        <SetTitle title={"Login"} />
        <LoggedOutHeader />

        <div className="center-container">
          <div className="login-box">
            <p className="welcome-to">Welcome to...</p>

            <BigLogo />

            <div className="login-form">
              <p>Please sign in to continue</p>
              <form onSubmit={this.handleSubmit}>
                <label>
                  <input placeholder=" email or username" aria-required="true" type="email" name="emailAddress" value={this.state.emailAddress} autoComplete="email" onChange={this.handleChange} />
                </label>
                <br />
                <label>
                  <input placeholder=" password" type="password" name="password" value={this.state.password} autoComplete="current-password" onChange={this.handleChange} />
                </label>
                <br />
                <input type="submit" value="Submit" />

                {this.loggingInMessage(this.state)}

                <Link to={{ pathname: '/forgotten-password', state: { emailAddress: this.state.emailAddress } }}>
                  <p className="italics">Forgotten your password?</p>
                </Link>
              </form>

              {
                this.renderErrors(this.state)
              }

              <div className="google-login-container">
                <GoogleOAuthProvider clientId="987710099044-h78amjc7vdebv45125mg8i6cd209aqa4.apps.googleusercontent.com">
                  <GoogleLogin onSuccess={this.handleGoogleLogin} onError={this.errorMessage} />
                </GoogleOAuthProvider>
              </div>

              {this.renderError(this.state.googleError)}

              <MsalProvider instance={mspca}>
                <div className="google-login-container">
                  <MicrosoftLoginButton handleMicrosoftLogin={(idToken) => this.handleMicrosoftLogin(idToken)} handleMicrosoftLoginError={(error) => this.handleMicrosoftLoginError(error)} />
                </div>
              </MsalProvider>

              {this.renderError(this.state.microsoftError)}

            </div>
          </div>

        </div>

      </div>
    );
  }
}

export default Login;
