import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Header from '../../components/header/Header';

import OutstandingActions from '../../components/exports/OutstandingActions';

import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';
import PopupBox from '../../components/shared/PopupBox';
import TypeOfPersonSelect from '../../components/shared/TypeOfPersonSelect';
import { debounce, optionalParam, buildParams, TYPE_OF_PEOPLE } from '../../utilities/Generic.js'

class OutstandingActionsIndex extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleMultiCheckbox = this.handleMultiCheckbox.bind(this);
    this.handleSelectActionStatuses = this.handleSelectActionStatuses.bind(this);
    this.handlePartialChange = this.handlePartialChange.bind(this);
    this.debouncedHandleSearch = debounce(this.handleSearch.bind(this), 500);
  }

  state = {
    question: "",
    location: "",
    type_of_person: "",
    action_statuses: [],
    selectActionStatuses: false,
    search: "",

    questions: [],
    locations: [],
    questionsLoaded: false,
    locationsLoaded: false,
    key: 0
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({
      [name]: value,
      key: Math.random()
    });

    let state = {
      date_from: this.state.date_from,
      date_to: this.state.date_to,
      question: this.state.question,
      location: this.state.location,
      type_of_person: this.state.type_of_person,
      action_statuses: this.state.action_statuses
    }

    state[name] = value

    let url = `/exports/outstanding-actions`

    const params = buildParams(
      optionalParam("question", state.question),
      optionalParam("location", state.location),
      optionalParam("type", state.type_of_person),
      optionalParam("action_statuses", state.action_statuses),
      optionalParam("search", this.state.search)
    )

    if (params) {
      url = url + "?" + params
    }

    this.props.history.push(url);
  }

  handleMultiCheckbox(event) {
    const name = event.target.name;
    const value = event.target.checked;

    const action_statuses = this.state.action_statuses

    if (value === true) {
      if (!action_statuses.includes(name)) {
        action_statuses.push(name)
      }
    }
    else if (value === false) {
      if (action_statuses.includes(name)) {
        const index = action_statuses.indexOf(name)
        action_statuses.splice(index, 1)
      }
    }

    this.setState({
      action_statuses: action_statuses,
      key: Math.random()
    });

    let url = `/exports/outstanding-actions`
    const params = buildParams(
      optionalParam("question", this.state.question),
      optionalParam("location", this.state.location),
      optionalParam("type", this.state.type_of_person),
      optionalParam("action_statuses", action_statuses),
      optionalParam("search", this.state.search)
    )

    if (params) {
      url = url + "?" + params
    }

    this.props.history.push(url);
  }

  handleSelectActionStatuses(event) {
    if (this.state.action_statuses.length === 0){
      this.setState((prevState) => ({
        selectActionStatuses: !prevState.selectActionStatuses
      }))
    }

    event.preventDefault();
  }

  handlePartialChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});

    this.debouncedHandleSearch()

    // This stops getDerivedStateFromProps using params to override this state change when typing immediately after hitting back/forward
    this.props.history.action = "PUSH"
  }

  handleSearch() {
    this.setState({
      key: Math.random()
    })

    let url = `/exports/outstanding-actions`
    const params = buildParams(
      optionalParam("question", this.state.question),
      optionalParam("location", this.state.location),
      optionalParam("type", this.state.type_of_person),
      optionalParam("action_statuses", this.state.action_statuses),
      optionalParam("search", this.state.search)
    )

    if (params) {
      url = url + "?" + params
    }

    this.props.history.push(url);
  }

  renderTimeScopeNotice(action_statuses) {
    if (action_statuses.includes("completed") || action_statuses.includes("abandoned")) {
      let text = "";
      if (action_statuses.includes("completed")) {
        text += "Completed ";
      }
      if (action_statuses.includes("completed") && action_statuses.includes("abandoned")) {
        text += "and ";
      }
      if (action_statuses.includes("abandoned")) {
        text += "Abandoned "
      }

      return (
        <>
          <br/>
          <div>Note: {text}Actions are only shown from within the last 3 months.</div>
        </>
      )
    }
  }

  render() {
    const { question, location, type_of_person, action_statuses, selectActionStatuses, search, questions, locations, questionsLoaded, locationsLoaded, unauthorized, error, key } = this.state;
    const { match: { params } } = this.props;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (questionsLoaded === false || locationsLoaded === false) {
      return (
        <div>
          <SetTitle title={"Outstanding Actions | Exports"} />
          <PopupBox />
          <Header />

          <div className="main-page">
            <h2 className="page-title">Outstanding Actions</h2>
            <OrganisationName />

            <p>Loading ...</p>
          </div>
        </div>
      )
    }

    return (
      <div>
        <SetTitle title={"Outstanding Actions | Exports"} />
        <PopupBox />
        <Header key={key} />

        <div className="main-page">
          <h2 className="page-title">Outstanding Actions</h2>
          <OrganisationName />

          <div className="export-selection">
            <div className="selection-block">
              <label className="column">Question:</label>
              <select name="question" value={question} onChange={this.handleChange} data-testid="select-questions">
                <option value="">All</option>
                {questions.map((question) => (
                  <option value={question.id} key={question.id}>{question.name}</option>
                ))}
              </select>
            </div>

            <div className="selection-block">
              <label className="column">Location:</label>
              <select name="location" value={location} onChange={this.handleChange} data-testid="select-locations">
                <option value="">All</option>
                {locations.map((location) => (
                  <option value={location.id} key={location.id}>{location.name}</option>
                ))}
              </select>
            </div>

            <div className="selection-block">
              <label className="column">Person Type:</label>
              <TypeOfPersonSelect defaultText="All" value={type_of_person} onChange={this.handleChange} options={["anonymous"].concat(TYPE_OF_PEOPLE)} />
            </div>

            <div className="selection-block">
              <label className="column">Search by Name:</label>
              <input type="text" name="search" value={search} onChange={this.handlePartialChange} />
            </div>

            <div className="selection-block">
              <div className="button" onClick={this.handleSelectActionStatuses}>Filter by Action Status</div>
            </div>

            <div className={selectActionStatuses ? "" : "hidden"}>
              <div className="selection-block right-label">
                <input type="checkbox" id="open" name="open" checked={action_statuses.includes("open")} onChange={this.handleMultiCheckbox} />
                <label htmlFor="open">Open</label>
              </div>

              <div className="selection-block right-label">
                <input type="checkbox" id="started" name="started" checked={action_statuses.includes("started")} onChange={this.handleMultiCheckbox} />
                <label htmlFor="started">Started</label>
              </div>

              <div className="selection-block right-label">
                <input type="checkbox" id="awaiting sign-off" name="awaiting sign-off" checked={action_statuses.includes("awaiting sign-off")} onChange={this.handleMultiCheckbox} />
                <label htmlFor="awaiting sign-off">Awaiting sign-off</label>
              </div>

              <div className="selection-block right-label">
                <input type="checkbox" id="blocked" name="blocked" checked={action_statuses.includes("blocked")} onChange={this.handleMultiCheckbox} />
                <label htmlFor="blocked">Blocked</label>
              </div>

              <div className="selection-block right-label">
                <input type="checkbox" id="completed" name="completed" checked={action_statuses.includes("completed")} onChange={this.handleMultiCheckbox} />
                <label htmlFor="completed">Completed</label>
              </div>

              <div className="selection-block right-label">
                <input type="checkbox" id="abandoned" name="abandoned" checked={action_statuses.includes("abandoned")} onChange={this.handleMultiCheckbox} />
                <label htmlFor="abandoned">Abandoned</label>
              </div>
            </div>
            {this.renderTimeScopeNotice(action_statuses)}
          </div>

          <OutstandingActions question={question} selected_location={location} type_of_person={type_of_person} action_statuses={action_statuses} search={search} key={this.state.key + 1} id={params.id} />

        </div>

      </div>
    );
  }

  static getDerivedStateFromProps(props, state) {
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.search !== "") {
      const params = new URLSearchParams(props.history.location.search)

      let question = params.get('question') || ""
      let location = params.get('location') || ""
      let type_of_person = params.get('type') || ""
      let action_statuses = params.get('action_statuses') === null ? [] : params.get('action_statuses').split(",")
      let selectActionStatuses = action_statuses.length > 0 ? true : state.selectActionStatuses;
      let search = params.get('search') || ""

      return {
        question: question,
        location: location,
        type_of_person: type_of_person,
        action_statuses: action_statuses,
        selectActionStatuses: selectActionStatuses,
        search: search,
        key: Math.random()
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep link params
    if (this.props.location.search !== "") {
      const params = new URLSearchParams(this.props.location.search)

      // grab query param or set default value
      const question = params.get('question') || "";
      const location = params.get('location') || "";
      const type_of_person = params.get('type') || "";
      const action_statuses = params.get('action_statuses') === null ? [] : params.get('action_statuses').split(",");
      const search = params.get('search') || "";

      // automatically show action status selection boxes if any are deep linked
      const selectActionStatuses = action_statuses.length > 0 ? true : false;

      this.setState({
        question: question,
        location: location,
        type_of_person: type_of_person,
        action_statuses: action_statuses,
        selectActionStatuses: selectActionStatuses,
        search: search,
        key: Math.random()
      });
    }

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ locations: data, locationsLoaded: true })
      })
      .catch(error => this.setState({ error, locationsLoaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/feedback_questions/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ questions: data, questionsLoaded: true })
      })
      .catch(error => this.setState({ error, questionsLoaded: true }))

    // get an outstanding feedback count unless it's already been grabbed within the last minute, to get the most accurate possible number
    let lastOutstandingFeedbackGet = localStorage.getItem("last_outstanding_actions_get");

    let currentTime = new Date();
    let oneMinuteAgo = currentTime.setMinutes(currentTime.getMinutes() - 1);

    if (lastOutstandingFeedbackGet < oneMinuteAgo) {
      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/outstanding_actions/outstanding_actions_count`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          localStorage.setItem("last_outstanding_actions_count", data.outstanding_actions)
          localStorage.setItem("last_outstanding_actions_get", new Date().valueOf())

          this.setState({ key: Math.random() });
        })
        .catch(error => this.setState({ error }))
    }
  }
}

export default OutstandingActionsIndex;
